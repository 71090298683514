import styled from "@emotion/styled";
import { RefreshOutlined } from "@mui/icons-material";
import Reorder from "@mui/icons-material/Reorder";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Node } from "@xyflow/react";
import { Button, Input, notification, Popconfirm, Popover, Select } from "antd";
import { produce } from "immer";
import * as R from "ramda";
import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DroppableProvided,
  DropResult,
} from "react-beautiful-dnd";
import { v5 as uuidv5 } from "uuid";
import LevelService from "../../../../../entities/model/LevelService";
import ModelService from "../../../../../entities/model/ModelService";
import VersionService from "../../../../../entities/model/VersionService";
import { fuzzyIsIn } from "../../../../../shared/helper/comparison";
import { EXCEPTION_VARIABLE_UUID, useICState } from "../../state";
import { Context, SContent } from "../components";
import { MappingField } from "../utils";
import IntegrationManager from "../utils/common";
import IntegrationFieldsManager from "../utils/fields";

import { ReactComponent as AddToFormulaIcon } from "../../../../../assets/add_to_formula.svg";
import { ReactComponent as CloseSolidIcon } from "../../../../../assets/close_solid.svg";
import { ReactComponent as EditIcon } from "../../../../../assets/edit.svg";
import { ReactComponent as LinkIcon } from "../../../../../assets/link.svg";
import { ReactComponent as NoIncomingFieldsIcon } from "../../../../../assets/no_incoming_fields.svg";
import { ReactComponent as SaveIcon } from "../../../../../assets/save.svg";
import { ReactComponent as UnLinkIcon } from "../../../../../assets/unlink.svg";

const NAMESPACE = "00000000-0000-0000-0000-000000000000";
const DATE_UUID = "ded4dcc5-27f2-45b5-9726-6ce078193e23";

const STables = styled.div(
  () =>
    ({
      width: "100%", // FIXME
      minHeight: "245px",
      height: "500px",
      overflow: "auto",
      borderRadius: "4px",
      padding: "8px",
      display: "flex",
      flexDirection: "row",
      gap: "80px",
      backgroundColor: `var(--Primary-Grey-025)`,
    }) as const,
);

const STableContainer = styled.div(
  () =>
    ({
      width: "100%", // FIXME: 528px
      height: "fit-content",
      position: "relative",
      borderRadius: "2px",
      backgroundColor: `var(--Primary-Grey-005)`,
      overflow: "hidden",

      // outline: "1px solid red", // FIXME
      // "*": {
      //   outline: "1px solid red",
      // },
    }) as const,
);

const STableTitle = styled.div(
  () =>
    ({
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      height: "38px",
      padding: "10px",
      fontSize: "14px",
      fontWeight: "bold",
      lineHeight: "18px",
      color: `var(--Black)`,
      textTransform: "uppercase",
      backgroundColor: `var(--Primary-Grey-005)`,
      borderBottom: "1px solid var(--Primary-Grey-025)",
    }) as const,
);

const STableBottom = styled.div(() => ({
  height: "40px",
  backgroundColor: `var(--Primary-Grey-005)`,
}));

const SHeaderCell = styled(TableCell)(() => ({
  height: "30px",
  padding: "0",
  paddingLeft: "10px",
  fontWeight: "bold",
  fontSize: "12px",
  borderBottom: "1px solid var(--Primary-Grey-025)",
  backgroundColor: `var(--Primary-Grey-010) !important`,
  lineHeight: 0,
}));

const SHeaderRow = styled(TableRow)(() => ({
  height: "30px",
  backgroundColor: `var(--Primary-Grey-010)`,
  borderBottom: "1px solid var(--Primary-Grey-025)",
}));

const SCell = styled(TableCell)(() => ({
  height: "38px",
  padding: "0px",
  paddingLeft: "10px",
  backgroundColor: `var(--White)`,
  borderBottom: "1px solid var(--Primary-Grey-025)",
  color: `var(--Primary-Grey-400)`,
  fontSize: "14px",
  fontWeight: "normal",
  lineHeight: 0,
}));

const IconContainer = styled.div(
  ({
    size,
    color,
    cursor,
  }: {
    size?: string;
    color?: string;
    cursor?: string;
  }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: size ? size : undefined,
    height: size ? size : undefined,
    cursor: cursor ? cursor : undefined,

    "*": {
      width: size ? "100%" : undefined,
      height: size ? "100%" : undefined,
      fill: color ? color : undefined,
    },
  }),
);

const IconButton = styled.button(({ size }: { size?: string }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  border: "unset",
  padding: "unset",
  backgroundColor: "unset",

  width: size ? size : undefined,
  height: size ? size : undefined,

  "*": {
    width: size ? "100%" : undefined,
    height: size ? "100%" : undefined,
    fill: `var(--Primary-Grey-100)`,
  },

  ":hover *": {
    fill: `var(--Primary-400)`,
  },
}));

const EmptyRows = ({ message }: { message: string }) => {
  return (
    <TableRow>
      <TableCell colSpan={100} height={"79px"} style={{ border: "unset" }}>
        <div
          style={{
            width: "100%",
            height: "fit-content",
          }}
        >
          <div
            style={{
              width: "fit-content",
              margin: "auto",
              display: "flex",
              alignItems: "center",
              gap: "4px",
            }}
          >
            <IconContainer color="var(--Primary-Grey-050)" size="24px">
              <NoIncomingFieldsIcon />
            </IconContainer>
            <div
              style={{
                display: "inline",
                color: "var(--Primary-Grey-100)",
              }}
            >
              {message}
            </div>
          </div>
        </div>
      </TableCell>
    </TableRow>
  );
};

enum VersionType {
  NEW = "new",
  BASE = "base",
  VARIABLE = "variable",
  CONSTANT = "constant",
}

type Version = {
  type: VersionType;
  value: any;
  label: string;
};

const BASE_VERSION: Version = {
  type: VersionType.BASE,
  value: "__BASE__",
  label: "🧱 Базовая",
};

const NEW_VERSION: Version = {
  type: VersionType.NEW,
  value: "__NEW__",
  label: "➕ Новая версия",
};

type Option = {
  label: string;
  value: string;
};

type Props = {
  id: string;
};

type State = {
  model: string;
  level: string;
  version: Version;
  formula: MappingField[];
  filter: string;
};

const initialState: State = {
  model: "",
  level: "",
  version: BASE_VERSION,
  formula: [],
  filter: "",
};

const PXCustomOutputWindow: React.FC<Props> = ({ id }) => {
  const { nodes, edges, variables, setNodes } = useICState();
  const { actions } = useContext(Context);

  const [state, setState] = useState<State>(initialState);

  useEffect(() => {
    actions.save = () => {
      setNodes(
        produce((nodes: Node[]) => {
          const node = nodes.find((node) => node.id === id);
          node.data.model = state.model;
          node.data.level = state.level;
          node.data.version = state.version.value;
          node.data.formula = state.formula;
          node.data.filter = state.filter;
        }),
      );
    };
  });

  const setStateKeyValue =
    (key: string) => (value: any | ((value: any) => any)) => {
      setState(
        produce((state) => {
          state[key] = typeof value === "function" ? value(state[key]) : value;
        }),
      );
    };

  const setModel = setStateKeyValue("model");
  const setLevel = setStateKeyValue("level");
  const setVersion = setStateKeyValue("version");
  const setFormula = setStateKeyValue("formula");
  const setFilter = setStateKeyValue("filter");

  const [inFs, setInFs] = useState<MappingField[]>([]);
  const [transform, setTransform] = useState<string>();

  const [fieldsEditList, setFsEditList] = useState<string[]>([]);

  const [modelOptions, setModelOptions] = useState<Option[]>([]);
  const [levelOptions, setLevelOptions] = useState<Option[]>([]);
  const [versions, setVersions] = useState<Option[]>([]);

  const versionOptions = useMemo(
    () => [
      {
        label: BASE_VERSION.label,
        value: BASE_VERSION.value,
        data: {
          value: BASE_VERSION.value,
          type: BASE_VERSION.type,
        },
      },
      {
        label: NEW_VERSION.label,
        value: NEW_VERSION.value,
        data: {
          value: NEW_VERSION.value,
          type: NEW_VERSION.type,
        },
      },
      ...variables
        .filter((v) => v.uuid !== EXCEPTION_VARIABLE_UUID)
        .map((variable) => ({
          label: `🧮 ${variable.id}`,
          value: `VARIABLE_${variable.id}`,
          data: {
            type: VersionType.VARIABLE,
            value: variable.id,
          },
        })),
      ...versions.map((version) => ({
        ...version,
        data: {
          type: VersionType.CONSTANT,
          value: version.value,
        },
      })),
    ],
    [versions, variables],
  );

  useEffect(() => {
    setTransform(null);

    const node = nodes.find((node) => node.id === id);

    if (node) {
      setState(
        produce((state) => {
          state.model = node.data.model ?? initialState.model;
          state.level = node.data.level ?? initialState.level;
          state.formula = node.data.formula ?? initialState.formula;
          state.version = !node.data.version
            ? initialState.version
            : // string comparison here for backward compatibility
              // because in previous versions version was stored as a string
              typeof node.data.version !== "string"
              ? node.data.version
              : node.data.version.startsWith("VARIABLE_")
                ? { value: node.data.version, type: VersionType.VARIABLE }
                : { value: node.data.version, type: VersionType.CONSTANT };
          state.filter = node.data.filter ?? initialState.filter;
        }),
      );
    }

    IntegrationManager.initial(id, nodes, edges, setInFs, setFormula);
  }, [id]);

  useEffect(() => {
    const getModelList = async () => {
      const getModels = await ModelService.getAll();
      if (getModels.code === 1) {
        setModelOptions((prevOptions: any) =>
          getModels.data.map((x: any) => ({
            label: x.name,
            value: x.id,
          })),
        );
      } else {
        notification.error({
          message: getModels.text,
          description: `Код ответа: ${getModels.code}`,
        });
      }
    };

    getModelList();
  }, []);

  useEffect(() => {
    const getLevelList = async () => {
      if (!state.model || state.model === "") {
        return;
      }
      const getLevels = await LevelService.getAll(state.model);
      if (getLevels.code === 1) {
        setLevelOptions((prevOptions: any) =>
          getLevels.data.map((x: any) => ({
            label: x.name,
            value: x.id,
          })),
        );
      }
    };

    getLevelList();
  }, [state.model]);

  useEffect(() => {
    const getVersionList = async () => {
      if (!state.model || !state.level) {
        return;
      }

      const getVersions = await VersionService.getAll({
        modelId: state.model,
        levelId: state.level,
      });

      if (getVersions.code === 1) {
        setVersions(
          getVersions.data.map((version: any) => ({
            label: version.name,
            value: version.id,
          })),
        );
      }
    };

    getVersionList();
  }, [state.model, state.level]);

  const getFields = async () => {
    if (!state.model || !state.level) {
      return;
    }

    const getAttributes = await LevelService.getAttributes(
      state.model,
      state.level,
    );
    if (getAttributes.code !== 1) {
      return;
    }
    const keyAttributes = getAttributes.data.filter((x: any) => x.key);

    const getFigures = await LevelService.getFigures(state.model, state.level);
    if (getFigures.code !== 1) {
      return;
    }

    const getPeriod = await LevelService.getPeriod(state.model, state.level);
    if (getPeriod.code !== 1) {
      return;
    }

    const fields: MappingField[] = [];

    for (const key of keyAttributes) {
      fields.push({
        uuid: uuidv5(key.attributeId, NAMESPACE),
        id: key.attributeId,
        desc: key.attributeName,
        type: "STRING",
      });
    }

    if (getPeriod.data > 0) {
      fields.push({
        uuid: DATE_UUID,
        id: "DATE",
        desc: "Дата",
        type: "DATE",
      });
    }

    for (const figure of getFigures.data) {
      fields.push({
        uuid: figure.uuid,
        id: "F_" + figure.id,
        desc: figure.name,
        type: "DECIMAL",
      });
    }

    setFormula((fs) =>
      fields.map((field: any) => {
        const f = fs.find((f) => f.uuid === field.uuid || f.id === field.id);
        const formula = f ? f.formula : undefined;
        return { ...field, formula };
      }),
    );
  };

  const outFsMap: Record<string, MappingField> = {};

  state.formula.forEach((f) => {
    outFsMap[f.uuid] = f;
  });

  const textAreaValue = outFsMap[transform]?.formula || "";

  const onMappingParamChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLTextAreaElement>) => {
    setState(
      produce((state) => {
        state.formula.forEach((f) => {
          if (f.uuid === transform) {
            f.formula = value;
          }
        });
      }),
    );
  };

  const createRFieldUpdater = (uuid: string, key: string) => (value: any) => {
    setFormula(
      produce((fields: any[]) => {
        fields.forEach((field) => {
          if (field.uuid === uuid) {
            field[key] = value;
          }
        });
      }),
    );
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    setFormula((fs: any) => {
      const temp = [...fs];
      const d = temp[result.destination!.index];
      temp[result.destination!.index] = temp[result.source.index];
      temp[result.source.index] = d;
      return temp;
    });
  };

  const inputTable = (
    <STableContainer>
      <STableTitle>Входящий поток</STableTitle>
      <div>
        <Table stickyHeader>
          <TableHead>
            <SHeaderRow>
              <SHeaderCell>ID</SHeaderCell>
              <SHeaderCell>Описание</SHeaderCell>
              <SHeaderCell>Тип</SHeaderCell>
              <SHeaderCell>
                <IconContainer
                  size="18px"
                  color="var(--Primary-Grey-100)"
                  cursor="pointer"
                  onClick={() =>
                    IntegrationFieldsManager.moveAllFieldsToRight({
                      sourceFields: inFs,
                      setTargetFields: setFormula,
                    })
                  }
                >
                  <LinkIcon />
                </IconContainer>
              </SHeaderCell>
            </SHeaderRow>
          </TableHead>
          <TableBody>
            {inFs.length > 0 ? (
              inFs.map((f, i) => (
                <TableRow key={i}>
                  <SCell>{f.id}</SCell>
                  <SCell>{f.desc}</SCell>
                  <SCell>{f.type}</SCell>
                  <SCell width="38px">
                    <IconContainer
                      size="18px"
                      color="var(--Primary-Grey-100)"
                      cursor="pointer"
                      onClick={() =>
                        IntegrationFieldsManager.moveFieldToTarget(
                          f.id,
                          inFs,
                          setFormula,
                        )
                      }
                    >
                      {IntegrationFieldsManager.getSourceFieldStatus(
                        f.id,
                        state.formula,
                      ) ? (
                        <LinkIcon />
                      ) : (
                        <UnLinkIcon />
                      )}
                    </IconContainer>
                  </SCell>
                </TableRow>
              ))
            ) : (
              <EmptyRows message="Нет входящих полей" />
            )}
          </TableBody>
        </Table>
      </div>
      {inFs.length > 0 && <STableBottom />}
    </STableContainer>
  );

  const outputTable = (
    <STableContainer>
      <STableTitle>
        <div>Исходящий поток</div>
        <Popconfirm
          title="Вы уверены что хотите загрузить поля?"
          description="Это приведет к удалению текущей конфигурации выходных полей."
          okText="Да"
          cancelText="Нет"
          onConfirm={() => getFields()}
        >
          <Button type="text" style={{ padding: "5px", margin: 0 }}>
            <RefreshOutlined />
          </Button>
        </Popconfirm>
      </STableTitle>

      <div>
        <Table stickyHeader>
          <TableHead>
            <SHeaderRow>
              <SHeaderCell />
              <SHeaderCell>ID</SHeaderCell>
              <SHeaderCell>Описание</SHeaderCell>
              <SHeaderCell>Тип</SHeaderCell>
              <SHeaderCell>Действие</SHeaderCell>
              <SHeaderCell />
            </SHeaderRow>
          </TableHead>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable" direction="vertical">
              {(dp: DroppableProvided) => (
                <TableBody ref={dp.innerRef} {...dp.droppableProps}>
                  {state.formula.length > 0 ? (
                    state.formula.map((f, i) => (
                      <Draggable key={f.uuid} draggableId={f.uuid} index={i}>
                        {(dp) => (
                          <TableRow ref={dp.innerRef} {...dp.draggableProps}>
                            <SCell width="38px">
                              <IconContainer
                                size="18px"
                                color="var(--Primary-Grey-100)"
                              >
                                {f.formula && f.formula.length > 0 ? (
                                  <LinkIcon />
                                ) : (
                                  <UnLinkIcon />
                                )}
                              </IconContainer>
                            </SCell>
                            <SCell>
                              <Popover content={outFsMap[f.uuid]?.id || ""}>
                                <Input
                                  variant={
                                    fieldsEditList.includes(f.uuid)
                                      ? "outlined"
                                      : "borderless"
                                  }
                                  disabled={!fieldsEditList.includes(f.uuid)}
                                  value={outFsMap[f.uuid]?.id || ""}
                                  onChange={R.pipe(
                                    R.path(["target", "value"]),
                                    createRFieldUpdater(f.uuid, "id"),
                                  )}
                                />
                              </Popover>
                            </SCell>
                            <SCell>
                              <Popover content={outFsMap[f.uuid]?.desc || ""}>
                                <Input
                                  variant={
                                    fieldsEditList.includes(f.uuid)
                                      ? "outlined"
                                      : "borderless"
                                  }
                                  disabled={!fieldsEditList.includes(f.uuid)}
                                  value={outFsMap[f.uuid]?.desc || ""}
                                  onChange={R.pipe(
                                    R.path(["target", "value"]),
                                    createRFieldUpdater(f.uuid, "desc"),
                                  )}
                                />
                              </Popover>
                            </SCell>
                            <SCell>
                              <Popover content={outFsMap[f.uuid]?.type || ""}>
                                <Select
                                  variant={
                                    fieldsEditList.includes(f.uuid)
                                      ? "outlined"
                                      : "borderless"
                                  }
                                  popupMatchSelectWidth={110}
                                  style={{ width: "110px" }}
                                  disabled={!fieldsEditList.includes(f.uuid)}
                                  value={outFsMap[f.uuid]?.type || ""}
                                  onChange={createRFieldUpdater(f.uuid, "type")}
                                  options={OPTIONS_TYPE}
                                />
                              </Popover>
                            </SCell>
                            <SCell>
                              <div
                                style={{
                                  display: "flex",
                                  gap: "8px",
                                  padding: "10px",
                                  alignItems: "center",
                                }}
                              >
                                {!fieldsEditList.includes(f.uuid) && (
                                  <IconButton
                                    size="18px"
                                    onClick={() =>
                                      IntegrationFieldsManager.enableFieldEditMode(
                                        f.uuid,
                                        setFsEditList,
                                      )
                                    }
                                  >
                                    <EditIcon />
                                  </IconButton>
                                )}
                                {fieldsEditList.includes(f.uuid) && (
                                  <IconButton
                                    size="18px"
                                    onClick={() =>
                                      IntegrationFieldsManager.disableFieldEditMode(
                                        f.uuid,
                                        state.formula,
                                        setFsEditList,
                                      )
                                    }
                                  >
                                    <SaveIcon />
                                  </IconButton>
                                )}
                                <IconButton
                                  size="18px"
                                  disabled={fieldsEditList.includes(f.uuid)}
                                  onClick={() => setTransform(f.uuid)}
                                >
                                  <AddToFormulaIcon />
                                </IconButton>
                                <Popconfirm
                                  title={`Вы действительно хотите удалить поле ${f.id}?`}
                                  okText="Да"
                                  cancelText="Нет"
                                  onConfirm={() =>
                                    IntegrationFieldsManager.removeField(
                                      f.uuid,
                                      setFormula,
                                    )
                                  }
                                >
                                  <IconButton size="18px">
                                    <CloseSolidIcon />
                                  </IconButton>
                                </Popconfirm>
                              </div>
                            </SCell>
                            <SCell>
                              <div {...dp.dragHandleProps}>
                                <Reorder />
                              </div>
                            </SCell>
                          </TableRow>
                        )}
                      </Draggable>
                    ))
                  ) : (
                    <EmptyRows message="Нет исходящих полей" />
                  )}
                  {dp.placeholder}
                </TableBody>
              )}
            </Droppable>
          </DragDropContext>
        </Table>
      </div>
      <Button
        type="dashed"
        style={{ marginTop: "5px", width: "100%" }}
        onClick={() =>
          IntegrationFieldsManager.addField(
            state.formula,
            setFormula,
            setFsEditList,
          )
        }
      >
        Добавить поле
      </Button>
    </STableContainer>
  );

  const modelComponent = (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "15px",
        alignItems: "center",
      }}
    >
      <div style={{ width: "190px", fontWeight: "bold" }}>Модель</div>
      <Select
        style={{ width: "300px", color: "black" }}
        value={state.model}
        onChange={setModel}
        options={modelOptions}
        showSearch={true}
        filterOption={(input, option?: Option) =>
          fuzzyIsIn(input, option?.label ?? "")
        }
      />
    </div>
  );

  const levelComponent = (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "15px",
        alignItems: "center",
      }}
    >
      <div style={{ width: "190px", fontWeight: "bold" }}>Уровень</div>
      <Select
        style={{ width: "300px", color: "black" }}
        value={state.level}
        onChange={setLevel}
        options={levelOptions}
        showSearch={true}
        filterOption={(input, option?: Option) =>
          fuzzyIsIn(input, option?.label ?? "")
        }
      />
    </div>
  );

  const versionComponent = (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "15px",
        alignItems: "center",
      }}
    >
      <div style={{ width: "190px", fontWeight: "bold" }}>Версия</div>
      <Select
        style={{ width: "300px", color: "black" }}
        value={state.version.value}
        onChange={(version) =>
          setVersion(versionOptions.find((x) => x.value === version).data)
        }
        options={versionOptions}
        showSearch={true}
        filterOption={(input, option?: Option) =>
          fuzzyIsIn(input, option?.label ?? "")
        }
      />
    </div>
  );

  const filterComponent = (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "15px",
        alignItems: "center",
      }}
    >
      <div style={{ width: "190px", fontWeight: "bold" }}>
        Фильтр синхронизации
      </div>
      <Input
        style={{ width: "800px" }}
        onChange={({ target: { value } }) => setFilter(value)}
        value={state.filter}
      />
    </div>
  );

  return (
    <SContent>
      {modelComponent}
      {levelComponent}
      {versionComponent}
      {filterComponent}

      <STables>
        {inputTable}
        {outputTable}
      </STables>

      <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
        <span>
          Параметр:
          {state.formula.find((f) => f.uuid === transform)?.id}
        </span>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "5px",
            height: "100px",
          }}
        >
          <Input.TextArea
            size="large"
            style={{ height: "100px", resize: "none" }}
            disabled={!transform}
            value={textAreaValue}
            onChange={onMappingParamChange}
          />
          <Button
            style={{ width: "100px", height: "100px", fontSize: "36px" }}
            onClick={() => console.log(nodes)}
          >
            👁
          </Button>
        </div>
      </div>
    </SContent>
  );
};

export default PXCustomOutputWindow;

const OPTIONS_TYPE = [
  {
    label: "Строка",
    options: [
      { value: "STRING", label: "STRING" },
      { value: "TEXT", label: "TEXT" },
    ],
  },
  {
    label: "Число",
    options: [
      { value: "INTEGER", label: "INTEGER" },
      { value: "DECIMAL", label: "DECIMAL" },
    ],
  },
  {
    label: "Дата и время",
    options: [
      { value: "DATE", label: "DATE" },
      { value: "DATETIME", label: "DATETIME" },
    ],
  },
  {
    label: "Булевы",
    options: [{ value: "BOOL", label: "BOOLEAN" }],
  },
];
