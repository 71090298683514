import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { Button, Drawer, Input, Select, Table, notification } from "antd";
import { TableProps } from "antd/lib";
import { useEffect, useState } from "react";
import AgentService from "../../../entities/model/AgentService";
import FlowService from "../../../entities/model/FlowService";
import TransferService from "../../../entities/model/TransferService";
import { EXCEPTION_VARIABLE_UUID } from "../integrationConstructor/state";

interface IIntegrationFlowLaunch {
  open: boolean;
  onClose: any;

  flowId: string | null | undefined;
}

type AgentsResponse = {
  code: number;
  data: any[];
};

const IntegrationFlowLaunch: React.FC<IIntegrationFlowLaunch> = ({
  open,
  onClose,
  flowId,
}) => {
  const [variables, setVariables] = useState<any>([]);
  const [agent, setAgent] = useState<string | null>(
    localStorage.getItem("agentSelected"),
  );

  const handleAgentSelect = (agent: string) => {
    setAgent(agent);
    localStorage.setItem("agentSelected", agent);
  };

  const {
    data: { data: agents } = { data: [] },
    isError: isAgentsError,
    isRefetching: isAgentsRefetching,
    isLoading: isAgentsLoading,
    refetch: refetchAgents,
  } = useQuery<AgentsResponse>({
    placeholderData: keepPreviousData,
    queryKey: ["integration-agents"],
    queryFn: async () => {
      const response = await AgentService.getAll();

      if (response.code !== 1) {
        throw new Error(
          `Ошибка при попытке загрузки списка агентов интеграции: "${response.text}"`,
        );
      }
      return response;
    },
  });

  const launchIntegration = async () => {
    if (!agent) {
      return notification.error({ message: "Не выбран агент" });
    }

    if (!flowId) {
      return notification.error({ message: "Ошибка при запуске потока" });
    }

    const response = await TransferService.launchFlow(agent, flowId, variables);
    if (response.code !== 1) {
      notification.error({ message: response.text });
      return;
    }

    notification.success({ message: `Поток поставлен в очередь` });
    onClose();
  };

  const fetchFlowData = async () => {
    if (flowId) {
      const response = await FlowService.getOne(flowId);

      if (response.code === 1) {
        setVariables(response.data.variables);
      }
    }
  };

  useEffect(() => {
    fetchFlowData();
  }, [open === true]);

  const variableColumns: TableProps<any>["columns"] = [
    {
      title: "Переменная",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Значение",
      dataIndex: "",
      key: "value",
      render: (_, record) => (
        <div style={{ display: "flex", flexDirection: "row", gap: "15px" }}>
          <Input
            defaultValue={record.defaultValue}
            value={record.value}
            onChange={(val: any) =>
              setVariables((prev: any) => {
                return prev.map((el: any) => {
                  if (el.id === record.id) {
                    el.value = val.target.value;
                  }
                  return el;
                });
              })
            }
          />
          <Button
            onClick={() =>
              setVariables((prev: any) => {
                return prev.map((el: any) => {
                  if (el.id === record.id) {
                    el.value = el.defaultValue;
                  }
                  return el;
                });
              })
            }
          >
            ↩️
          </Button>
        </div>
      ),
    },
  ];

  return (
    <Drawer title="Запуск потока" open={open} onClose={onClose} width={"500px"}>
      <div style={{ display: "flex", flexDirection: "column", gap: "25px" }}>
        <span>
          Вы собираетесь запустить поток{" "}
          <span style={{ fontWeight: "bold" }}>{flowId}</span> на агенте{" "}
        </span>
        <Select
          getPopupContainer={() => document.getElementById("area")!}
          placeholder="Выберите агент"
          options={agents.map(({ id }) => ({ value: id, label: id }))}
          value={agent}
          onChange={handleAgentSelect}
          style={{ width: "200px" }}
        />
        {variables.length > 0 && (
          <>
            <Table
              columns={variableColumns}
              dataSource={variables.filter(
                (v) => v.uuid !== EXCEPTION_VARIABLE_UUID,
              )}
              pagination={false}
            />
          </>
        )}
        <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
          <Button size="large" type={"primary"} onClick={launchIntegration}>
            Поставить в очередь
          </Button>
          <Button size="large" danger onClick={onClose}>
            Отменить
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default IntegrationFlowLaunch;
