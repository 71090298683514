/**
 * Triggers file downloading in browser.
 * @param name
 * @param blob
 */
export const download = (name: string, blob: Blob) => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = name;
  document.body.appendChild(a);
  a.click();
  a.remove();
};
