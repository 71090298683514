import { Level, LevelCreate } from "../../entities/types/ILevel";
import { BackResponse, IBackResponse } from "../../entities/types/response";
import $api, { API_URL } from "../../shared/api/axios";
import UserService from "./UserService";

export default class LevelService {
  static async getAll(modelId: string): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xModel}/level/getList`, {
      modelId,
    });

    response.data.data = response.data.data.map(
      (model: any) => new Level(model),
    );
    for (const element of response.data.data) {
      element.createdBy = await UserService.getUsernameById(element.createdBy);
      element.updatedBy = await UserService.getUsernameById(element.updatedBy);
    }

    return response.data;
  }

  static async create(level: LevelCreate): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xModel}/level/create`, {
      ...level,
    });
    return new BackResponse(response.data);
  }

  static async delete(model: string, level: string): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xModel}/level/delete`, {
      model,
      level,
    });
    return new BackResponse(response.data);
  }

  static async visualize(level: any): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xModel}/level/visualize`, {
      level,
    });

    return new BackResponse(response.data);
  }

  static async getAttributes(
    model: string,
    level: string,
  ): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xModel}/level/getattributes`, {
      model,
      level,
    });

    return new BackResponse(response.data);
  }

  static async getFigures(
    model: string,
    level: string,
    version: string = "BASE",
  ): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xModel}/level/getfigures`, {
      model,
      level,
      version,
    });

    return new BackResponse(response.data);
  }

  static async getPeriod(model: string, level: string): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xModel}/level/getperiod`, {
      model,
      level,
    });

    return new BackResponse(response.data);
  }

  static async addAttribute(
    modelId: string,
    levelId: string,
    attributeId: string,
  ): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xModel}/level/assign`, {
      modelId,
      levelId,
      attributeId,
    });
    return new BackResponse(response.data);
  }

  static async removeAttribute(
    modelId: string,
    levelId: string,
    attributeId: any,
  ): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xModel}/level/unassign`, {
      modelId,
      levelId,
      attributeId,
    });
    return new BackResponse(response.data);
  }

  static async startSync(
    modelId: string,
    levelId: string,
  ): Promise<IBackResponse> {
    const response = await $api.post(
      `${API_URL.xObject}/object/nonrootsrefresh`,
      { modelId, levelId },
    );
    return new BackResponse(response.data);
  }

  static async startOptimize(
    model: string,
    level: string,
  ): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xSyncAgent}/sync/optimize`, {
      model,
      level,
    });
    return new BackResponse(response.data);
  }
}
