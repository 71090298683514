import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Node } from "@xyflow/react";
import { Input, Select, notification } from "antd";
import Empty from "antd/lib/empty";
import { produce } from "immer";
import * as R from "ramda";
import { useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import TransferService from "../../../../../entities/model/TransferService";
import { fuzzyIsIn } from "../../../../../shared/helper/comparison";
import { useICState } from "../../state";
import { Context, SContent } from "../components";
import { MappingField } from "../utils";

type Option = {
  label: string;
  value: string;
};

type Props = {
  id: string;
};

type State = {
  hanaConn: string;
  schema: string;
  table: string;
  formula: MappingField[];
  filter: string;
  limit: string;
};

const initialState: State = {
  hanaConn: "",
  schema: "",
  table: "",
  formula: [],
  filter: "",
  limit: "",
};

const HanaInput: React.FC<Props> = ({ id }) => {
  const { nodes, setNodes } = useICState();
  const { actions } = useContext(Context);

  const [state, setState] = useState<State>(initialState);

  useEffect(() => {
    actions.save = () => {
      setNodes(
        produce((nodes: Node[]) => {
          const node = nodes.find((node) => node.id === id);
          node.data.hanaConn = state.hanaConn;
          node.data.schema = state.schema;
          node.data.table = state.table;
          node.data.formula = state.formula;
          node.data.filter = state.filter;
          node.data.limit = state.limit;
        }),
      );
    };
  });

  useEffect(() => {
    const node = nodes.find((node) => node.id === id);

    if (node) {
      getConn();

      setState(
        produce((state) => {
          state.hanaConn = node.data.hanaConn ?? initialState.hanaConn;
          state.schema = node.data.schema ?? initialState.schema;
          state.table = node.data.table ?? initialState.table;
          state.formula = node.data.formula ?? initialState.formula;
          state.filter = node.data.filter ?? initialState.filter;
          state.limit = node.data.limit ?? initialState.limit;
        }),
      );
    }
  }, [id]);

  const setStateKeyValue = (key: string) => (value: any) => {
    setState(
      produce((state) => {
        state[key] = value;
      }),
    );
  };

  const setConn = setStateKeyValue("hanaConn");
  const setSchema = setStateKeyValue("schema");
  const setTable = setStateKeyValue("table");
  const setFormula = setStateKeyValue("formula");
  const setFilter = setStateKeyValue("filter");
  const setLimit = setStateKeyValue("limit");

  const [connOptions, setConnOptions] = useState<Option[]>([]);
  const [schemaOptions, setSchemaOptions] = useState<Option[]>([]);
  const [tableOptions, setTableOptions] = useState<Option[]>([]);

  const getConn = async () => {
    const getSystems = await TransferService.getAll({
      filters: [{ id: "system", value: "SAPHANA" }],
    });
    setConnOptions(
      getSystems.data.rows.map((el: any) => ({ label: el.id, value: el.id })),
    );
  };

  const getSchema = async () => {
    if (!state.hanaConn) {
      return;
    }
    const getSchemas = await TransferService.getHanaSchemas(state.hanaConn);
    if (getSchemas.code === 1) {
      setSchemaOptions(
        getSchemas.data.map((el: any) => ({ label: el, value: el })),
      );
    } else {
      notification.warning({ message: "Не удалось получить схемы" });
    }
  };

  useEffect(() => {
    getSchema();
  }, [state.hanaConn]);

  const getTable = async () => {
    if (!state.hanaConn || !state.schema) {
      return;
    }
    const getTables = await TransferService.getHanaTables(
      state.hanaConn,
      state.schema,
    );
    if (getTables.code === 1) {
      setTableOptions(
        getTables.data.map((el: any) => ({ label: el, value: el })),
      );
    } else {
      notification.warning({ message: "Не удалось получить таблицы" });
    }
  };

  useEffect(() => {
    getTable();
  }, [state.schema]);

  const getField = async () => {
    if (!state.hanaConn || !state.schema || !state.table) {
      return;
    }
    const getFields = await TransferService.getHanaFields(
      state.hanaConn,
      state.schema,
      state.table,
    );
    if (getFields.code === 1) {
      setFormula(
        getFields.data.map((el: any) => ({
          uuid: uuidv4(),
          id: el.columnName,
          type: getMappingField(el.dataType),
        })),
      );
    } else {
      notification.warning({ message: "Не удалось получить таблицы" });
    }
  };

  useEffect(() => {
    getField();
  }, [state.table]);

  const filterComponent = (
    <div
      style={{
        minHeight: "60px",
        display: "flex",
        flexDirection: "column",
        gap: "15px",
      }}
    >
      <div style={{ fontWeight: "bold" }}>Фильтр</div>
      <Input.TextArea
        style={{ color: "black" }}
        value={state.filter}
        onChange={R.pipe(R.path(["target", "value"]), setFilter)}
        allowClear={true}
      />
    </div>
  );

  const limitComponent = (
    <div
      style={{
        minHeight: "60px",
        display: "flex",
        flexDirection: "column",
        gap: "15px",
      }}
    >
      <div style={{ fontWeight: "bold" }}>Лимит</div>
      <Input.TextArea
        style={{ color: "black" }}
        value={R.defaultTo(null)(state.limit)}
        onChange={R.pipe(
          R.path<string>(["target", "value"]),
          R.when(R.test(/^\s*$/g), () => undefined),
          R.defaultTo(undefined),
          setLimit,
        )}
        allowClear={true}
      />
    </div>
  );

  return (
    <SContent>
      <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>Подключение</div>
          <Select
            value={state.hanaConn}
            style={{ width: "300px", color: "black" }}
            onChange={(el) => setConn(el)}
            options={connOptions}
            showSearch={true}
            filterOption={(input, option?: Option) =>
              fuzzyIsIn(input, option?.label ?? "")
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>Схема</div>
          <Select
            value={state.schema}
            style={{ width: "300px", color: "black" }}
            onChange={(el) => setSchema(el)}
            options={schemaOptions}
            showSearch={true}
            filterOption={(input, option?: Option) =>
              fuzzyIsIn(input, option?.label ?? "")
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>Таблица</div>
          <Select
            value={state.table}
            style={{ width: "300px", color: "black" }}
            onChange={(el) => setTable(el)}
            options={tableOptions}
            showSearch={true}
            filterOption={(input, option?: Option) =>
              fuzzyIsIn(input, option?.label ?? "")
            }
          />
        </div>
        {filterComponent}
        {limitComponent}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>
                Идентификатор
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Описание</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Тип</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {state.formula?.length > 0 ? (
              state.formula.map((el: MappingField, ind: number) => (
                <TableRow key={ind}>
                  <TableCell>{el.id}</TableCell>
                  <TableCell>{el.desc}</TableCell>
                  <TableCell>{el.type}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={100}>
                  <Empty
                    imageStyle={{ height: "50px" }}
                    description="Нет исходящих полей"
                  />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </SContent>
  );
};

export default HanaInput;

const getMappingField = (type: string) => {
  switch (type) {
    case "character varying":
      return "STRING";
    case "double precision":
      return "DECIMAL";
    case "datetime":
      return "DATETIME";
    case "date":
      return "DATE";
    case "integer":
      return "INTEGER";
    case "uuid":
      return "UUID";
    case "numeric":
      return "DECIMAL";
    case "boolean":
      return "STRING";
    case "timestamp without time zone":
      return "DATE";
    case "bigint":
      return "INTEGER";
    default:
      return "STRING";
  }
};
