import $api, { API_URL } from "../../shared/api/axios";
import { Source, Task } from "../types/ISource";
import { BackResponse, IBackResponse } from "../types/response";

export default class TransferService {
  static async getAll(
    params: {
      page?: number;
      size?: number;
      filters?: any[];
      sorting?: any[];
    } = {},
  ): Promise<IBackResponse<{ count: number; rows: any[] }>> {
    const response = await $api.post(
      `${API_URL.xTransfer}/source/list`,
      params,
    );

    response.data.data.rows = response.data.data.rows.map(
      (model: any) => new Source(model),
    );

    return response.data;
  }

  static async getSource(id: string): Promise<
    IBackResponse<{
      id: string;
      system: string;
      connection: Record<string, unknown>;
    }>
  > {
    const response = await $api.get(`${API_URL.xTransfer}/source/${id}`);
    return response.data;
  }

  static async updateSource(
    id: string,
    system: string,
    info: any,
  ): Promise<IBackResponse> {
    const response = await $api.put(`${API_URL.xTransfer}/source/${id}`, {
      system,
      info,
    });
    return response.data;
  }

  static async getAllTasks(): Promise<IBackResponse> {
    const response = await $api.get(`${API_URL.xTransfer}/task/getlist`);
    response.data.data = response.data.data.map(
      (model: any) => new Task(model),
    );

    return response.data;
  }

  static async create(
    id: string,
    system: string,
    info: any,
  ): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xTransfer}/source/create`, {
      id,
      system,
      info,
    });
    return new BackResponse(response.data);
  }

  static async delete(id: string): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xTransfer}/source/delete`, {
      id,
    });
    return new BackResponse(response.data);
  }

  static async testConnectionKS(
    url: string,
    login: string,
    password: string,
  ): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xKS}/user/check`, {
      url,
      login,
      password,
    });
    return new BackResponse(response.data);
  }

  static async testConnectionHana(
    host: string,
    port: string | number,
    user: string,
    password: string,
  ): Promise<IBackResponse> {
    const response = await $api.post(
      `${API_URL.xTransfer}/source/checkhanaconnection`,
      {
        host,
        port,
        user,
        password,
      },
    );
    return new BackResponse(response.data);
  }

  static async createConnectionHana(
    id: string,
    host: string,
    port: string | number,
    user: string,
    password: string,
  ): Promise<IBackResponse> {
    const response = await $api.post(
      `${API_URL.xTransfer}/source/createhanaconnection`,
      {
        id,
        host,
        port,
        user,
        password,
      },
    );
    return new BackResponse(response.data);
  }

  static async getHanaSchemas(sourceId: string): Promise<IBackResponse> {
    const response = await $api.post(
      `${API_URL.xTransfer}/source/gethanaschema`,
      { sourceId },
    );
    return new BackResponse(response.data);
  }

  static async getHanaTables(
    sourceId: string,
    schemaName: string,
  ): Promise<IBackResponse> {
    const response = await $api.post(
      `${API_URL.xTransfer}/source/gethanatables`,
      { sourceId, schemaName },
    );
    return new BackResponse(response.data);
  }

  static async getHanaFields(
    sourceId: string,
    schemaName: string,
    tableName: string,
  ): Promise<IBackResponse> {
    const response = await $api.post(
      `${API_URL.xTransfer}/source/gethanafields`,
      { sourceId, schemaName, tableName },
    );
    return new BackResponse(response.data);
  }

  static async testConnectionPG(
    host: string,
    database: string,
    user: string,
    password: string,
  ): Promise<IBackResponse> {
    const response = await $api.post(
      `${API_URL.xTransfer}/source/checkpgconnection`,
      {
        host,
        database,
        user,
        password,
      },
    );
    return new BackResponse(response.data);
  }

  static async createConnectionPG(
    id: string,
    host: string,
    database: string,
    user: string,
    password: string,
  ): Promise<IBackResponse> {
    const response = await $api.post(
      `${API_URL.xTransfer}/source/createpgconnection`,
      {
        id,
        host,
        database,
        user,
        password,
      },
    );
    return new BackResponse(response.data);
  }

  static async getPostgresSchemas(sourceId: string): Promise<IBackResponse> {
    const response = await $api.post(
      `${API_URL.xTransfer}/source/getpgschema`,
      { sourceId },
    );
    return new BackResponse(response.data);
  }

  static async getPostgresTables(
    sourceId: string,
    schemaName: string,
  ): Promise<IBackResponse> {
    const response = await $api.post(
      `${API_URL.xTransfer}/source/getpgtables`,
      { sourceId, schemaName },
    );
    return new BackResponse(response.data);
  }

  static async getPostgresFields(
    sourceId: string,
    schemaName: string,
    tableName: string,
  ): Promise<IBackResponse> {
    const response = await $api.post(
      `${API_URL.xTransfer}/source/getpgfields`,
      { sourceId, schemaName, tableName },
    );
    return new BackResponse(response.data);
  }

  static async fileImport(form: FormData): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xObject}/object/file`, form);
    return new BackResponse(response.data);
  }

  static async launchFlow(
    agent: string,
    flow: string,
    variables?: any,
  ): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xTransfer}/flow/launch`, {
      agent,
      flow,
      variables,
    });
    console.log(response.data);
    return new BackResponse(response.data);
  }

  static async testConnectionClickhouse(
    host: string,
    database: string,
    user: string,
    password: string,
  ): Promise<IBackResponse> {
    const response = await $api.post(
      `${API_URL.xTransfer}/source/checkclickhouseconnection`,
      {
        host,
        database,
        user,
        password,
      },
    );
    return new BackResponse(response.data);
  }

  static async createConnectionClickhouse(
    id: string,
    host: string,
    database: string,
    user: string,
    password: string,
  ): Promise<IBackResponse> {
    const response = await $api.post(
      `${API_URL.xTransfer}/source/createclickhouseconnection`,
      {
        id,
        host,
        database,
        user,
        password,
      },
    );
    return new BackResponse(response.data);
  }

  static async getClickhouseTables(sourceId: string): Promise<IBackResponse> {
    const response = await $api.post(
      `${API_URL.xTransfer}/source/getclickhousetables`,
      { sourceId },
    );
    return new BackResponse(response.data);
  }

  static async getClickhouseFields(
    sourceId: string,
    tableName: string,
  ): Promise<IBackResponse> {
    const response = await $api.post(
      `${API_URL.xTransfer}/source/getclickhousefields`,
      { sourceId, tableName },
    );
    return new BackResponse(response.data);
  }

  static async createConnectionRabbitMQ(
    id: string,
    host: string,
    user: string,
    password: string,
  ): Promise<IBackResponse> {
    const response = await $api.post(
      `${API_URL.xTransfer}/source/createrabbitmqconnection`,
      {
        id,
        host,
        user,
        password,
      },
    );
    return new BackResponse(response.data);
  }

  static async testConnectionRabbitMQ(
    host: string,
    user: string,
    password: string,
  ): Promise<IBackResponse> {
    const response = await $api.post(
      `${API_URL.xTransfer}/source/checkrabbitmqconnection`,
      {
        host,
        user,
        password,
      },
    );
    return new BackResponse(response.data);
  }
}
