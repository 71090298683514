import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import type { TabsProps } from "antd";
import {
  Drawer,
  Input,
  InputNumber,
  Popconfirm,
  Switch,
  Tabs,
  Tag,
} from "antd";
import { Modal, notification } from "antd/lib";
import { format } from "date-fns";
import React, { RefObject, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppContext } from "../../..";
import FigureService from "../../../entities/model/FigureService";
import LevelService from "../../../entities/model/LevelService";
import ModelService from "../../../entities/model/ModelService";
import UserService from "../../../entities/model/UserService";
import { IConversion } from "../../../entities/types/IConversion";
import { IFigure } from "../../../entities/types/IFigure";
import { ILevel } from "../../../entities/types/ILevel";
import {
  IAttributeModel,
  IModelCreate,
  IModelGeneralInfo,
  ITimeModel,
} from "../../../entities/types/IModel";
import LevelModal from "../../../features/componets/LevelModal";
import Button from "../../../shared/components/button";
import { Loader } from "../../../shared/components/loader";
import AttributePopover from "../../../shared/components/popovers/attribute";
import UserPopover from "../../../shared/components/popovers/user";
import { exportToExcel } from "../../../shared/helper/excelExport";
import PageError500 from "../../errors/error500";
import ConversionCreatePage from "../conversion/createConversion";
import FigureCreatePage from "../figure/createFigure";
import FigureEditPage from "../figure/editFigure";
import FiguresVisualizePage from "../figuresVisualize";
import LevelCreatePage from "../level/createLevel";
import ChangeAttributeDictionary from "./drawers/changeAttributeDictionary";
import PageModelVersion from "./sections/version";

const { Search } = Input;

const ModelViewPage: React.FC = () => {
  const [stateNotExist, setStateNotExist] = useState<boolean>(false);
  const { store } = useAppContext();

  const params = useParams();
  const model: string = params.modelID || "";
  useEffect(() => {
    store.setPageName(`Просмотр модели: ${model}`);
  }, []);

  const tabs: TabsProps["items"] = [
    {
      key: "1",
      label: "Основное",
      children: (
        <GeneralModelInfo
          stateNotExist={stateNotExist}
          setStateNotExist={setStateNotExist}
        />
      ),
    },
    {
      key: "2",
      label: "Атрибуты",
      children: <AttributeModelInfo />,
    },
    {
      key: "3",
      label: "Уровни",
      children: <LevelModelInfo />,
    },
    {
      key: "4",
      label: "Показатели",
      children: <FigureModelInfo />,
    },
    {
      key: "5",
      label: "Конверсия",
      children: <ConversionModelInfo />,
    },
    {
      key: "6",
      label: "Операторы",
      children: "В разработке",
    },
    {
      key: "7 ",
      label: "Версии",
      children: <PageModelVersion />,
    },
    {
      key: "8 ",
      label: "Пользователи",
      children: <UserModelInfo />,
    },
  ];

  return (
    <>
      {stateNotExist && (
        <PageError500 message={"Похоже, что такой модели не существует!"} />
      )}
      {!stateNotExist && (
        <Tabs
          defaultActiveKey="1"
          items={tabs}
          onChange={onTabChange}
          style={{ fontWeight: "bold" }}
        />
      )}
    </>
  );
};

export default ModelViewPage;

interface TimeLevel {
  name: string;
  enabled: boolean;
  past: number | null;
  future: number | null;
}

interface IGeneralModelInfo {
  stateNotExist: boolean;
  setStateNotExist: any;
}

export const GeneralModelInfo: React.FC<IGeneralModelInfo> = ({
  stateNotExist,
  setStateNotExist,
}) => {
  const params = useParams();
  const model: string = params.modelID || "";
  const [modelID, setModelID] = useState<string>(model);
  const [modelName, setModelName] = useState<string>("");
  const [modelDescription, setModelDescription] = useState<string>("");
  const [modelCreatedAt, SetModelCreatedAt] = useState<string>("");
  const [modelUpdatedAt, SetModelUpdatedAt] = useState<string>("");
  const [modelCreatedBy, SetModelCreatedBy] = useState<string>("");
  const [modelUpdatedBy, SetModelUpdatedBy] = useState<string>("");

  const [commonInfoState, setCommonInfoState] = useState<boolean>(false);
  const [timeState, setTimeState] = useState<boolean>(false);
  const [adminState, setAdminState] = useState<boolean>(false);

  const [editMode, setEditMode] = useState<boolean>(false);

  const [timeDay, setTimeDay] = useState<TimeLevel>({
    name: "День",
    enabled: false,
    past: 0,
    future: 0,
  });
  const [timeTWeek, setTimeTWeek] = useState<TimeLevel>({
    name: "Техническая неделя",
    enabled: false,
    past: 0,
    future: 0,
  });
  const [timeCWeek, setTimeCWeek] = useState<TimeLevel>({
    name: "Календарная неделя",
    enabled: false,
    past: 0,
    future: 0,
  });
  const [timeMonth, setTimeMonth] = useState<TimeLevel>({
    name: "Месяц",
    enabled: false,
    past: 0,
    future: 0,
  });
  const [timeQuarter, setTimeQuarter] = useState<TimeLevel>({
    name: "Квартал",
    enabled: false,
    past: 0,
    future: 0,
  });
  const [timeYear, setTimeYear] = useState<TimeLevel>({
    name: "Год",
    enabled: false,
    past: 0,
    future: 0,
  });

  const UpdateInfo = async () => {
    setCommonInfoState(true);
    if (modelName === "" || !modelName) {
      return notification.warning({
        message: "Наименование модели не может быть пустым",
      });
    }

    const body: IModelCreate = {
      id: model,
      name: modelName,
      description: modelDescription,
    };

    const response = await ModelService.update(body);
    setCommonInfoState(false);
    setEditMode(false);
    return notification.info({
      message: response.text,
      description: `Код ответа: ${response.code}`,
    });
  };

  const UpdateTimeLevel = async () => {
    setTimeState(true);
    const bodyDay: ITimeModel = {
      model,
      time: "DAY",
      enabled: timeDay.enabled,
      future: timeDay.future,
      past: timeDay.past,
    };
    const responseDay = await ModelService.updateTimeLevel(bodyDay);
    notification.info({
      message: responseDay.text,
      description: `[День] Код ответа: ${responseDay.code}`,
    });

    // TWeek
    const bodyTWeek: ITimeModel = {
      model,
      time: "TWEEK",
      enabled: timeTWeek.enabled,
      future: timeTWeek.future,
      past: timeTWeek.past,
    };
    const responseTWeek = await ModelService.updateTimeLevel(bodyTWeek);
    notification.info({
      message: responseTWeek.text,
      description: `[Тех. неделя] Код ответа: ${responseTWeek.code}`,
    });

    // СWeek
    const bodyCWeek: ITimeModel = {
      model,
      time: "CWEEK",
      enabled: timeCWeek.enabled,
      future: timeCWeek.future,
      past: timeCWeek.past,
    };
    const responseCWeek = await ModelService.updateTimeLevel(bodyCWeek);
    notification.info({
      message: responseCWeek.text,
      description: `[Кал. неделя] Код ответа: ${responseTWeek.code}`,
    });

    // Month
    const bodyMonth: ITimeModel = {
      model,
      time: "MONTH",
      enabled: timeMonth.enabled,
      future: timeMonth.future,
      past: timeMonth.past,
    };
    const responseMonth = await ModelService.updateTimeLevel(bodyMonth);
    notification.info({
      message: responseMonth.text,
      description: `[Месяц] Код ответа: ${responseTWeek.code}`,
    });

    // Quarter
    const bodyQuarter: ITimeModel = {
      model,
      time: "QUARTER",
      enabled: timeQuarter.enabled,
      future: timeQuarter.future,
      past: timeQuarter.past,
    };
    const responseQuarter = await ModelService.updateTimeLevel(bodyQuarter);
    notification.info({
      message: responseQuarter.text,
      description: `[Квартал] Код ответа: ${responseQuarter.code}`,
    });

    // Year
    const bodyYear: ITimeModel = {
      model,
      time: "YEAR",
      enabled: timeYear.enabled,
      future: timeYear.future,
      past: timeYear.past,
    };
    const responseYear = await ModelService.updateTimeLevel(bodyYear);
    notification.info({
      message: responseYear.text,
      description: `[Год] Код ответа: ${responseYear.code}`,
    });
    setTimeState(false);
  };

  const modelGeneralFetch = async () => {
    setCommonInfoState(true);
    setTimeState(true);
    setAdminState(true);
    try {
      const response: IModelGeneralInfo = (
        await ModelService.generalInfo(model)
      ).data;

      setModelID(response.model.id);
      setModelName(response.model.name);
      setModelDescription(response.model.description);
      SetModelCreatedAt(
        format(new Date(response.model.createdAt), "dd.MM.yyyy HH:mm:ss"),
      );
      const createdBy = response.model.createdBy;

      const updatedBy = response.model.updatedBy;

      SetModelCreatedBy(createdBy);
      SetModelUpdatedAt(
        format(new Date(response.model.updatedAt), "dd.MM.yyyy HH:mm:ss"),
      );
      SetModelUpdatedBy(updatedBy);

      for (const tlevel of response.time) {
        switch (tlevel.time) {
          case "DAY": {
            setTimeDay({
              ...timeDay,
              enabled: tlevel.enabled,
              past: tlevel.past,
              future: tlevel.future,
            });
            break;
          }
          case "TWEEK": {
            setTimeTWeek({
              ...timeTWeek,
              enabled: tlevel.enabled,
              past: tlevel.past,
              future: tlevel.future,
            });
            break;
          }
          case "CWEEK": {
            setTimeCWeek({
              ...timeCWeek,
              enabled: tlevel.enabled,
              past: tlevel.past,
              future: tlevel.future,
            });
            break;
          }
          case "MONTH": {
            setTimeMonth({
              ...timeMonth,
              enabled: tlevel.enabled,
              past: tlevel.past,
              future: tlevel.future,
            });
            break;
          }
          case "QUARTER": {
            setTimeQuarter({
              ...timeQuarter,
              enabled: tlevel.enabled,
              past: tlevel.past,
              future: tlevel.future,
            });
            break;
          }
          case "YEAR": {
            setTimeYear({
              ...timeYear,
              enabled: tlevel.enabled,
              past: tlevel.past,
              future: tlevel.future,
            });
            break;
          }
        }
      }

      setCommonInfoState(false);
      setTimeState(false);
      setAdminState(false);
    } catch {
      setStateNotExist(true);
    }
  };

  useEffect(() => {
    modelGeneralFetch();
  }, []);

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
      <span style={{ fontSize: 18, fontWeight: 700 }}>
        <span style={{ color: "#37AB49", fontWeight: 900 }}>1.</span> Общая
        информация
      </span>
      <TableContainer>
        {commonInfoState && <Loader />}
        {!commonInfoState && (
          <Table style={{ width: "700px" }}>
            <TableBody>
              <TableRow>
                <TableCell style={{ fontWeight: "bold" }}>
                  Идентификатор
                </TableCell>
                <TableCell>
                  <Input style={{ color: "black" }} value={modelID} disabled />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ fontWeight: "bold" }}>
                  Наименование
                </TableCell>
                <TableCell>
                  <Input
                    style={{ color: "black" }}
                    value={modelName}
                    disabled={!editMode}
                    onChange={(el) => setModelName(el.target.value)}
                  />
                </TableCell>
                <TableCell>
                  {editMode && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "15px",
                      }}
                    >
                      <Button
                        type="dashed"
                        label="💾 Сохранить"
                        onClick={UpdateInfo}
                      />
                      <Button
                        type="dashed"
                        label="↩ Отменить"
                        onClick={() => setEditMode(false)}
                      />
                    </div>
                  )}
                  {!editMode && (
                    <Button
                      type="dashed"
                      label="📝 Изменить"
                      onClick={() => {
                        setEditMode(true);
                      }}
                    />
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ fontWeight: "bold" }}>Описание</TableCell>
                <TableCell>
                  <Input
                    style={{ color: "black" }}
                    value={modelDescription}
                    disabled={!editMode}
                    onChange={(el) => setModelDescription(el.target.value)}
                  />
                </TableCell>
                <TableCell>
                  {editMode && (
                    <Button
                      type="dashed"
                      label="💾 Сохранить"
                      onClick={UpdateInfo}
                    />
                  )}
                  {!editMode && (
                    <Button
                      type="dashed"
                      label="📝 Изменить"
                      onClick={() => {
                        setEditMode(true);
                      }}
                    />
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}
      </TableContainer>
      <span style={{ fontSize: 18, fontWeight: 700 }}>
        <span style={{ color: "#37AB49", fontWeight: 900 }}>2.</span> Настройки
        временной гранулярности
      </span>
      <TableContainer>
        <div style={{ marginBottom: "15px" }}>
          <Button
            label="💾 Сохранить настройки времени"
            type="primary"
            onClick={UpdateTimeLevel}
          />
        </div>
        {timeState && <Loader />}
        <Table style={{ width: "700px" }}>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>Включено</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>
                Гранулярность
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }}>
                Периоды в прошлом
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }}>
                Периоды в будущем
              </TableCell>
            </TableRow>
          </TableHead>
          {!timeState && (
            <TableBody>
              <TableRow>
                <TableCell>
                  <Switch
                    checked={timeDay.enabled}
                    onChange={(el: boolean) => {
                      setTimeDay({ ...timeDay, enabled: el });
                    }}
                  />
                </TableCell>
                <TableCell>{timeDay.name}</TableCell>
                <TableCell>
                  <InputNumber
                    min={0}
                    value={timeDay.past}
                    onChange={(el: number | null) =>
                      setTimeDay({ ...timeDay, past: el })
                    }
                  />
                </TableCell>
                <TableCell>
                  <InputNumber
                    min={0}
                    value={timeDay.future}
                    onChange={(el: number | null) =>
                      setTimeDay({ ...timeDay, future: el })
                    }
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Switch
                    checked={timeTWeek.enabled}
                    onChange={(el: boolean) =>
                      setTimeTWeek({ ...timeTWeek, enabled: el })
                    }
                  />
                </TableCell>
                <TableCell>{timeTWeek.name}</TableCell>
                <TableCell>
                  <InputNumber
                    min={0}
                    value={timeTWeek.past}
                    onChange={(el: number | null) =>
                      setTimeTWeek({ ...timeTWeek, past: el })
                    }
                  />
                </TableCell>
                <TableCell>
                  <InputNumber
                    min={0}
                    value={timeTWeek.future}
                    onChange={(el: number | null) =>
                      setTimeTWeek({ ...timeTWeek, future: el })
                    }
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Switch
                    checked={timeCWeek.enabled}
                    onChange={(el: boolean) =>
                      setTimeCWeek({ ...timeCWeek, enabled: el })
                    }
                  />
                </TableCell>
                <TableCell>{timeCWeek.name}</TableCell>
                <TableCell>
                  <InputNumber
                    min={0}
                    value={timeCWeek.past}
                    onChange={(el: number | null) =>
                      setTimeCWeek({ ...timeCWeek, past: el })
                    }
                  />
                </TableCell>
                <TableCell>
                  <InputNumber
                    min={0}
                    value={timeCWeek.future}
                    onChange={(el: number | null) =>
                      setTimeCWeek({ ...timeCWeek, future: el })
                    }
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Switch
                    checked={timeMonth.enabled}
                    onChange={(el: boolean) =>
                      setTimeMonth({ ...timeMonth, enabled: el })
                    }
                  />
                </TableCell>
                <TableCell>{timeMonth.name}</TableCell>
                <TableCell>
                  <InputNumber
                    min={0}
                    value={timeMonth.past}
                    onChange={(el: number | null) =>
                      setTimeMonth({ ...timeMonth, past: el })
                    }
                  />
                </TableCell>
                <TableCell>
                  <InputNumber
                    min={0}
                    value={timeMonth.future}
                    onChange={(el: number | null) =>
                      setTimeMonth({ ...timeMonth, future: el })
                    }
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Switch
                    checked={timeQuarter.enabled}
                    onChange={(el: boolean) =>
                      setTimeQuarter({ ...timeQuarter, enabled: el })
                    }
                  />
                </TableCell>
                <TableCell>{timeQuarter.name}</TableCell>
                <TableCell>
                  <InputNumber
                    min={0}
                    value={timeQuarter.past}
                    onChange={(el: number | null) =>
                      setTimeQuarter({ ...timeQuarter, past: el })
                    }
                  />
                </TableCell>
                <TableCell>
                  <InputNumber
                    min={0}
                    value={timeQuarter.future}
                    onChange={(el: number | null) =>
                      setTimeQuarter({ ...timeQuarter, future: el })
                    }
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Switch
                    checked={timeYear.enabled}
                    onChange={(el: boolean) =>
                      setTimeYear({ ...timeYear, enabled: el })
                    }
                  />
                </TableCell>
                <TableCell>{timeYear.name}</TableCell>
                <TableCell>
                  <InputNumber
                    min={0}
                    value={timeYear.past}
                    onChange={(el: number | null) =>
                      setTimeYear({ ...timeYear, past: el })
                    }
                  />
                </TableCell>
                <TableCell>
                  <InputNumber
                    min={0}
                    value={timeYear.future}
                    onChange={(el: number | null) =>
                      setTimeYear({ ...timeYear, future: el })
                    }
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>

      <span style={{ fontSize: 18, fontWeight: 700 }}>
        <span style={{ color: "#37AB49", fontWeight: 900 }}>3.</span>{" "}
        Административная информация
      </span>
      <TableContainer>
        {adminState && <Loader />}
        <Table style={{ width: "700px" }}>
          {!adminState && (
            <TableBody>
              <TableRow>
                <TableCell style={{ fontWeight: "bold" }} width={"20%"}>
                  Создал
                </TableCell>
                <TableCell width={"40%"}>
                  {modelCreatedBy && <UserPopover userId={modelCreatedBy} />}
                </TableCell>
                <TableCell width={"40%"}>{modelCreatedAt}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ fontWeight: "bold" }} width={"20%"}>
                  Изменил
                </TableCell>
                <TableCell width={"40%"}>
                  {modelUpdatedBy && <UserPopover userId={modelUpdatedBy} />}
                </TableCell>
                <TableCell width={"40%"}>{modelUpdatedAt}</TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </div>
  );
};

export const UserModelInfo: React.FC = () => {
  const params = useParams();
  const modelId: string = params.modelID || "";

  const [filterText, setFilterText] = useState("");

  const sharedQuery = useQuery({
    queryKey: ["model-users", modelId],
    queryFn: async () => {
      const response = await ModelService.getUsers(modelId);

      if (response.code !== 1) {
        throw new Error(response.text);
      }

      return response.data;
    },
  });

  const usersQuery = useQuery({
    queryKey: ["users"],
    queryFn: async () => {
      const response = await UserService.getUserList();

      if (response.code !== 1) {
        throw new Error(response.text);
      }

      return response.data;
    },
  });

  if (sharedQuery.isLoading || usersQuery.isLoading) {
    return <Loader />;
  }

  const sharedUsers = new Set(sharedQuery.data.map((user: any) => user.id));

  const users = usersQuery.data
    .filter((user) => {
      const searchText = filterText.toLowerCase();
      return (
        user.id.toString().toLowerCase().includes(searchText) ||
        user.name.toLowerCase().includes(searchText) ||
        user.surname.toLowerCase().includes(searchText) ||
        user.email.toLowerCase().includes(searchText)
      );
    })
    .map((user: any) => ({
      ...user,
      isShared: sharedUsers.has(user.id),
    }));

  const onUnshare = async (userId: number) => {
    const response = await ModelService.unshare(modelId, userId);

    const notificationFn =
      response.code !== 1 ? notification.error : notification.success;

    notificationFn({
      message: response.text,
    });

    usersQuery.refetch();
    sharedQuery.refetch();
  };

  const onShare = async (userId: number) => {
    const response = await ModelService.share(modelId, userId);

    const notificationFn =
      response.code !== 1 ? notification.error : notification.success;

    notificationFn({
      message: response.text,
    });

    usersQuery.refetch();
    sharedQuery.refetch();
  };

  return (
    <TableContainer>
      <Input
        placeholder="Поиск"
        style={{ marginTop: "15px", marginBottom: "15px" }}
        value={filterText}
        onChange={(e) => setFilterText(e.target.value)}
      />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ fontWeight: "bold" }}>Аватар</TableCell>
            <TableCell style={{ fontWeight: "bold" }}>ИД</TableCell>
            <TableCell style={{ fontWeight: "bold" }}>Имя</TableCell>
            <TableCell style={{ fontWeight: "bold" }}>Фамилия</TableCell>
            <TableCell style={{ fontWeight: "bold" }}>Email</TableCell>
            <TableCell style={{ fontWeight: "bold" }}>Активирован</TableCell>
            <TableCell style={{ fontWeight: "bold" }}>Заблокирован</TableCell>
            <TableCell style={{ fontWeight: "bold" }}>Роли</TableCell>
            <TableCell style={{ fontWeight: "bold" }}>Фильтры</TableCell>
            <TableCell style={{ fontWeight: "bold" }}>Действия</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user) => (
            <TableRow>
              <TableCell>
                {user.avatar ? (
                  <img src={user.avatar} width={70} alt={user.surname} />
                ) : (
                  "-"
                )}
              </TableCell>
              <TableCell>{user.id}</TableCell>
              <TableCell>{user.name}</TableCell>
              <TableCell>{user.surname}</TableCell>
              <TableCell>{user.email}</TableCell>
              <TableCell>
                {user.activated ? (
                  <Tag color="green">ДА</Tag>
                ) : (
                  <Tag color="red">НЕТ</Tag>
                )}
              </TableCell>
              <TableCell>
                {user.blocked ? (
                  <Tag color="green">ДА</Tag>
                ) : (
                  <Tag color="red">НЕТ</Tag>
                )}
              </TableCell>
              <TableCell>
                {user.roles.map((role: string) => (
                  <Tag color="orange-inverse">{role}</Tag>
                ))}
              </TableCell>
              <TableCell>
                {user.filters.map((filter: string) => (
                  <Tag>{filter}</Tag>
                ))}
              </TableCell>
              <TableCell>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "15px",
                  }}
                >
                  {user.isShared ? (
                    <Button
                      type="dashed"
                      label="Удалить"
                      onClick={() => onUnshare(user.id)}
                    />
                  ) : (
                    <Button
                      type="primary"
                      label="Добавить"
                      onClick={() => onShare(user.id)}
                    />
                  )}
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const AttributeModelInfo: React.FC = () => {
  const params = useParams();
  const model: string = params.modelID || "";
  const [attributes, setAttributes] = useState<IAttributeModel[]>();
  const [addModalState, setAddModalState] = useState<boolean>(false);
  const [availableAttributes, setAvailableAttributes] =
    useState<IAttributeModel[]>();
  const [filterText, setFilterText] = useState("");
  const [filterTextAdd, setFilterTextAdd] = useState("");
  const [listState, setListState] = useState<boolean>(false);
  const [attrListState, setAttrListState] = useState<boolean>(false);

  const [changeDictionaryAttribute, setChangeDictionaryAttribute] = useState<
    string | null
  >(null);
  const [changeDictionaryModal, setChangeDictionaryModal] =
    useState<boolean>(false);

  const modelAttributesFetch = async () => {
    setListState(true);
    const response = await ModelService.modelAttributes(model);
    setAttributes(response.data);
    setListState(false);
  };

  useEffect(() => {
    modelAttributesFetch();
  }, []);

  const fetchAvailableAttributes = async () => {
    setAttrListState(true);
    const response = await ModelService.availableDictionaryAttributes(model);
    setAvailableAttributes(response.data);
    setAttrListState(false);
  };

  const openAddModal = async () => {
    await fetchAvailableAttributes();
    setAddModalState(true);
  };

  const closeAddModal = async () => {
    setAddModalState(false);
  };

  const assignAttribute = async (attribute: string, dictionary: string) => {
    const response = await ModelService.assignAttribute(
      attribute,
      dictionary,
      model,
    );
    notification.info({
      message: response.text,
      description: `Код ответа: ${response.code}`,
    });
    modelAttributesFetch();
    await fetchAvailableAttributes();
  };

  const onRemoveAttribute = async (attribute: string) => {
    const response = await ModelService.removeAttribute(attribute, model);
    notification.info({
      message: response.text,
      description: `Код ответа: ${response.code}`,
    });
    modelAttributesFetch();
  };

  const handleOpenChangeDictionary = (attribute: string) => {
    setChangeDictionaryAttribute(attribute);
    setChangeDictionaryModal(true);
  };

  const handleCloseChangeDictionary = () => {
    setChangeDictionaryModal(false);
    setChangeDictionaryAttribute(null);
  };

  return (
    <>
      <TableContainer>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "15px",
            marginBottom: "15px",
          }}
        >
          <span>Всего атрибутов: {attributes ? attributes.length : 0}</span>
          <Button label="📁 Добавить" type="primary" onClick={openAddModal} />
          <Button
            label="🔄 Обновить"
            type="primary"
            onClick={modelAttributesFetch}
          />
        </div>
        <Input
          placeholder="Поиск"
          style={{ marginTop: "15px", marginBottom: "15px" }}
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
          allowClear
        />
        {listState && <Loader />}
        {!listState && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: "bold" }}>
                  Ид атрибута
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Наименование атрибута
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Ид словаря</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Наименование словаря
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Действие</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {attributes &&
                attributes
                  .filter((attribute) => {
                    const searchText = filterText.toLowerCase();
                    return (
                      attribute.attributeId
                        .toLowerCase()
                        .includes(searchText) ||
                      attribute.attributeName.toLowerCase().includes(searchText)
                    );
                  })
                  .map((attribute: IAttributeModel) => {
                    return (
                      <TableRow key={attribute.attributeId}>
                        <TableCell>
                          <AttributePopover
                            attributeId={attribute.attributeId}
                          />
                        </TableCell>
                        <TableCell>{attribute.attributeName}</TableCell>
                        <TableCell>{attribute.dictionaryId}</TableCell>
                        <TableCell>{attribute.dictionaryName}</TableCell>
                        <TableCell>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "15px",
                            }}
                          >
                            <Button
                              label="🔃 Переназначить"
                              type="dashed"
                              size="small"
                              onClick={() =>
                                handleOpenChangeDictionary(
                                  attribute.attributeId,
                                )
                              }
                            />
                            <Popconfirm
                              title="Отвязка атрибута"
                              description={`Вы действительно хотите отвязать атрибут ${attribute.attributeId}?`}
                              onConfirm={() =>
                                onRemoveAttribute(attribute.attributeId)
                              }
                              okText="⚠ Да, удалить"
                              cancelText="Нет, отменить"
                            >
                              <Button
                                label="🚫 Удалить"
                                type="dashed"
                                size="small"
                              />
                            </Popconfirm>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      <Modal
        destroyOnClose
        title="Добавить атрибут"
        open={addModalState}
        onCancel={() => {
          setAddModalState(false);
          setFilterTextAdd("");
        }}
        width={"1200px"}
        footer={[
          <Button
            key="cancel"
            onClick={closeAddModal}
            type="primary"
            label="Закрыть"
          />,
        ]}
      >
        <TableContainer>
          <Input
            placeholder="Поиск"
            style={{ marginTop: "15px", marginBottom: "15px" }}
            value={filterTextAdd}
            onChange={(e) => setFilterTextAdd(e.target.value)}
            allowClear
          />
          {attrListState && <Loader />}
          {!attrListState && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Ид атрибута
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Наименование атрибута
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Ид словаря
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Наименование словаря
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Действие</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {availableAttributes &&
                  availableAttributes
                    .filter((attribute) => {
                      const searchText = filterTextAdd.toLowerCase();
                      return (
                        attribute.attributeId
                          .toLowerCase()
                          .includes(searchText) ||
                        attribute.attributeName
                          .toLowerCase()
                          .includes(searchText)
                      );
                    })
                    .map((attribute: IAttributeModel, index: number) => {
                      return (
                        <TableRow key={index}>
                          <TableCell style={{ fontWeight: "bold" }}>
                            {attribute.attributeId}
                          </TableCell>
                          <TableCell>{attribute.attributeName}</TableCell>
                          <TableCell>{attribute.dictionaryId}</TableCell>
                          <TableCell>{attribute.dictionaryName}</TableCell>
                          <TableCell>
                            <Button
                              label="📁 Добавить"
                              type="dashed"
                              onClick={async () =>
                                assignAttribute(
                                  attribute.attributeId,
                                  attribute.dictionaryId,
                                )
                              }
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
              </TableBody>
            </Table>
          )}
        </TableContainer>
      </Modal>
      <Drawer
        open={changeDictionaryModal}
        title={`Перепривязывание атрибута ${changeDictionaryAttribute}`}
        onClose={handleCloseChangeDictionary}
      >
        <ChangeAttributeDictionary
          changeDictionaryAttribute={changeDictionaryAttribute}
          setChangeDictionaryModal={setChangeDictionaryModal}
          setChangeDictionaryAttribute={setChangeDictionaryAttribute}
          modelId={model}
          fetchAttributes={modelAttributesFetch}
        />
      </Drawer>
    </>
  );
};

export const LevelModelInfo: React.FC = () => {
  const params = useParams();
  const model: string = params.modelID || "";
  const [levels, setLevels] = useState<ILevel[]>();
  const [addModalState, setAddModalState] = useState<boolean>(false);
  const [filterText, setFilterText] = useState("");
  const [listState, setListState] = useState<boolean>(false);

  const modalRef: RefObject<any> = useRef();

  const modelLevelsFetch = async () => {
    setListState(true);
    const response = await LevelService.getAll(model);
    setLevels(response.data);
    setListState(false);
  };

  useEffect(() => {
    modelLevelsFetch();
  }, []);

  const openAddModal = async () => {
    setAddModalState(true);
  };

  const closeAddModal = async () => {
    setAddModalState(false);
    modelLevelsFetch();
  };

  const onDeleteLevel = async (level: string) => {
    const response = await LevelService.delete(model, level);
    notification.warning({
      message: response.text,
      description: `Код ответа: ${response.code}`,
    });
    modelLevelsFetch();
  };

  const startLevelSync = async (level: string) => {
    await LevelService.startSync(model, level);
  };

  const startLevelOptimize = async (level: string) => {
    await LevelService.startOptimize(model, level);
  };

  return (
    <>
      <TableContainer>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "15px",
            marginBottom: "15px",
          }}
        >
          <span>Всего уровней: {levels ? levels.length : 0}</span>
          <Button label="📁 Добавить" type="primary" onClick={openAddModal} />
          <Button
            label="🔄 Обновить"
            type="primary"
            onClick={modelLevelsFetch}
          />
        </div>
        <Input
          placeholder="Поиск"
          style={{ marginTop: "15px", marginBottom: "15px" }}
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
          allowClear
        />
        {listState && <Loader />}
        {!listState && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: "bold" }}>Ид уровня</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Наименование уровня
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Описание уровня
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Действие</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {levels &&
                levels
                  .filter((level) => {
                    const searchText = filterText.toLowerCase();
                    return (
                      level.id.toLowerCase().includes(searchText) ||
                      level.name.toLowerCase().includes(searchText)
                    );
                  })
                  .map((level: ILevel) => {
                    return (
                      <TableRow key={level.id}>
                        <TableCell>{level.id}</TableCell>
                        <TableCell>{level.name}</TableCell>
                        <TableCell>{level.description}</TableCell>
                        <TableCell>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "10px",
                            }}
                          >
                            <Popconfirm
                              title="Синхронизация уровеня"
                              description={`Вы действительно хотите запустить оптимизацию уровня ${level.id}? Это может занять некоторое время.`}
                              onConfirm={() => startLevelOptimize(level.id)}
                              okText="⚠ Да, запустить"
                              cancelText="Нет, отменить"
                            >
                              <Button
                                label="⚙ Оптимизировать"
                                type="dashed"
                                size="small"
                              />
                            </Popconfirm>
                            <Popconfirm
                              title="Синхронизация уровеня"
                              description={`Вы действительно хотите запустить синхронизацию уровня ${level.id}? Это может занять некоторое время.`}
                              onConfirm={() => startLevelSync(level.id)}
                              okText="⚠ Да, запустить"
                              cancelText="Нет, отменить"
                            >
                              <Button
                                label="🔃 Синхронизировать"
                                type="dashed"
                                size="small"
                              />
                            </Popconfirm>
                            <Button
                              label="⚙️ Настройки"
                              type="dashed"
                              size="small"
                              onClick={() =>
                                modalRef.current?.openEditModal(model, level)
                              }
                            />
                            <Popconfirm
                              title="Удаление показателя"
                              description={`Вы действительно хотите удалить уровень ${level.id}?`}
                              onConfirm={() => onDeleteLevel(level.id)}
                              okText="⚠ Да, удалить"
                              cancelText="Нет, отменить"
                            >
                              <Button
                                label="🚫 Удалить"
                                type="dashed"
                                size="small"
                              />
                            </Popconfirm>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      <Modal
        title="Добавить уровень"
        destroyOnClose
        open={addModalState}
        onCancel={() => setAddModalState(false)}
        width={"855px"}
        footer={[
          <Button
            key="cancel"
            onClick={closeAddModal}
            type="dashed"
            label="Отменить"
          />,
        ]}
      >
        <LevelCreatePage
          model={model}
          modalState={setAddModalState}
          dataFetch={modelLevelsFetch}
        />
      </Modal>
      <LevelModal apiRef={modalRef} fetchData={modelLevelsFetch} />
    </>
  );
};

export const FigureModelInfo: React.FC = () => {
  const params = useParams();
  const model: string = params.modelID || "";
  const [figures, setFigures] = useState<IFigure[]>();
  const [addModalState, setAddModalState] = useState<boolean>(false);
  const [filterText, setFilterText] = useState("");
  const [graphModalState, setGraphModalState] = useState<boolean>(false);
  const [graphFigure, setGraphFigure] = useState<string>("");
  const [changeModalState, setChangeModalState] = useState<boolean>(false);
  const [changeFigure, setChangeFigure] = useState<string>("");
  const [changeFigureData, setChangeFigureData] = useState<IFigure>();
  const [listState, setListState] = useState<boolean>(false);

  const modelFiguresFetch = async () => {
    setListState(true);
    const response = await FigureService.getAll(model);
    setFigures(response.data);
    setListState(false);
  };

  useEffect(() => {
    modelFiguresFetch();
  }, []);

  const openAddModal = async () => {
    setAddModalState(true);
  };

  const closeAddModal = async () => {
    setAddModalState(false);
    modelFiguresFetch();
  };

  const onGraphOpen = (figure: string) => {
    setGraphModalState(true);
    setGraphFigure(figure);
  };

  const onChangeOpen = (figure: string) => {
    setChangeFigure(figure);
    setChangeModalState(true);
  };

  const onDeleteFigure = async (figure: string) => {
    const response = await FigureService.delete(model, figure);
    modelFiguresFetch();
    notification.warning({
      message: response.text,
      description: `Код ответа: ${response.code}`,
    });
  };

  return (
    <>
      <TableContainer>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "15px",
            marginBottom: "15px",
          }}
        >
          <span>Всего показателей: {figures ? figures.length : 0}</span>
          <Button label="📁 Добавить" type="primary" onClick={openAddModal} />
          <Button
            label="🔄 Обновить"
            type="primary"
            onClick={modelFiguresFetch}
          />
          <Button
            label="📤 Экспорт"
            type="primary"
            onClick={() => exportToExcel(`Показатели_${model}`, figures)}
          />
          <Button label="📤 Импорт" type="primary" disabled />
        </div>
        <Input
          placeholder="Поиск"
          style={{ marginTop: "15px", marginBottom: "15px" }}
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
          allowClear
        />
        {listState && <Loader />}
        {!listState && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: "bold" }}>Ид</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Наименование
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Уровень</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Тип</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Формула</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Агрегация</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Дезагрегация
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Действие</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {figures &&
                figures
                  .filter((figure) => {
                    const searchText = filterText.toLowerCase();
                    return (
                      figure.id.toLowerCase().includes(searchText) ||
                      figure.name.toLowerCase().includes(searchText) ||
                      figure.level.id.toLowerCase().includes(searchText) ||
                      figure.level.name.toLowerCase().includes(searchText)
                    );
                  })
                  .map((figure: IFigure) => {
                    return (
                      <TableRow key={figure.id}>
                        <TableCell>{figure.id}</TableCell>
                        <TableCell>{figure.name}</TableCell>
                        <TableCell>
                          {figure.level.name} ({figure.level.id})
                        </TableCell>
                        <TableCell>
                          <Tag
                            color={
                              figure.type === "STORED"
                                ? "green-inverse"
                                : "blue-inverse"
                            }
                            style={{ width: "135px", fontSize: "14px" }}
                          >
                            {figure.type === "STORED"
                              ? "Хранимый"
                              : "Рассчитываемый"}
                          </Tag>
                        </TableCell>
                        <TableCell style={{ maxWidth: "400px" }}>
                          {formatFormula(figure.formulacalc)}
                        </TableCell>
                        <TableCell>
                          <Tag>{figure.aggmode}</Tag>
                          <span> / </span>
                          <Tag>{figure.aggtimemode}</Tag>
                        </TableCell>
                        <TableCell>
                          <Tag>{figure.disaggmode}</Tag>
                          <span> / </span>
                          <Tag>{figure.disaggtimemode}</Tag>
                        </TableCell>
                        <TableCell>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "10px",
                            }}
                          >
                            <Button
                              label="📝 Изменить"
                              type="dashed"
                              onClick={() => onChangeOpen(figure.id)}
                              size="small"
                            />
                            <Button
                              label="🧮 Граф"
                              type="dashed"
                              onClick={() => onGraphOpen(figure.id)}
                              size="small"
                            />
                            <Popconfirm
                              title="Удаление показателя"
                              description={`Вы действительно хотите удалить показатель ${figure.id}?`}
                              onConfirm={() => onDeleteFigure(figure.id)}
                              okText="⚠ Да, удалить"
                              cancelText="Нет, отменить"
                            >
                              <Button
                                label="🚫 Удалить"
                                type="dashed"
                                size="small"
                              />
                            </Popconfirm>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      <Modal
        title="Добавить показатель"
        open={addModalState}
        onCancel={() => setAddModalState(false)}
        width={"700px"}
        footer={[]}
        destroyOnClose
      >
        <FigureCreatePage
          model={model}
          setModalState={setAddModalState}
          fetchData={modelFiguresFetch}
        />
      </Modal>
      <Modal
        title="Граф вычислений"
        open={graphModalState}
        onCancel={() => setGraphModalState(false)}
        width="80%"
      >
        <FiguresVisualizePage model={model} figure={graphFigure} />
      </Modal>
      <Modal
        title="Изменить показатель"
        open={changeModalState}
        onCancel={() => setChangeModalState(false)}
        width={"700px"}
        footer={[]}
        destroyOnClose
      >
        <FigureEditPage
          model={model}
          figure={changeFigure}
          onFinish={() => {
            setChangeModalState(false);
            modelFiguresFetch();
          }}
        />
      </Modal>
    </>
  );
};

const ConversionModelInfo: React.FC = () => {
  const params = useParams();
  const model: string = params.modelID || "";
  const [conversions, setConversions] = useState<IConversion[]>();
  const [addModalState, setAddModalState] = useState<boolean>(false);
  const [filterText, setFilterText] = useState("");
  const [listState, setListState] = useState<boolean>(false);

  const modelConversionsFetch = async () => {
    setListState(true);
    const response = await ModelService.getConversion(model);
    console.log(response.data);
    setConversions(response.data);
    setListState(false);
  };

  useEffect(() => {
    modelConversionsFetch();
  }, []);

  const openAddModal = async () => {
    setAddModalState(true);
  };

  const closeAddModal = async () => {
    setAddModalState(false);
    modelConversionsFetch();
  };

  const onDeleteConversion = async (conversionId: string) => {
    const response = await ModelService.deleteConversion(conversionId);
    notification.warning({
      message: response.text,
      description: `Код ответа: ${response.code}`,
    });
    modelConversionsFetch();
  };

  return (
    <>
      <TableContainer>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "15px",
            marginBottom: "15px",
          }}
        >
          <span>Всего конверсий: {conversions ? conversions.length : 0}</span>
          <Button label="📁 Добавить" type="primary" onClick={openAddModal} />
          <Button
            label="🔄 Обновить"
            type="primary"
            onClick={modelConversionsFetch}
          />
        </div>
        <Input
          placeholder="Поиск"
          style={{ marginTop: "15px", marginBottom: "15px" }}
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
          allowClear
        />
        {listState && <Loader />}
        {!listState && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: "bold" }}>
                  Идентификатор
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Наименование
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Атрибут единиц
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Уровень</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Показатель</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Атрибут "Из"
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Атрибут "В"
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Действие</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {conversions &&
                conversions
                  .filter((conversion) => {
                    const searchText = filterText.toLowerCase();
                    return (
                      conversion.id.toLowerCase().includes(searchText) ||
                      conversion.name.toLowerCase().includes(searchText)
                    );
                  })
                  .map((conversion: IConversion) => {
                    return (
                      <TableRow key={conversion.id}>
                        <TableCell>{conversion.id}</TableCell>
                        <TableCell>{conversion.name}</TableCell>
                        <TableCell>{conversion.attributeId}</TableCell>
                        <TableCell>{conversion.levelId}</TableCell>
                        <TableCell>{conversion.figureId}</TableCell>
                        <TableCell>{conversion.attributeFromId}</TableCell>
                        <TableCell>{conversion.attributeToId}</TableCell>
                        <TableCell>
                          <Popconfirm
                            title="Удаление конверсии"
                            description={`Вы действительно хотите удалить конверсию ${conversion.id}?`}
                            onConfirm={() => onDeleteConversion(conversion.id)}
                            okText="⚠ Да, удалить"
                            cancelText="Нет, отменить"
                          >
                            <Button label="🚫 Удалить" type="primary" />
                          </Popconfirm>
                        </TableCell>
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      <Modal
        title="Добавить конверсию"
        open={addModalState}
        onCancel={() => setAddModalState(false)}
        width={"855px"}
        footer={[
          <Button
            key="cancel"
            onClick={closeAddModal}
            type="dashed"
            label="Отменить"
          />,
        ]}
      >
        <ConversionCreatePage
          model={model}
          modalState={setAddModalState}
          dataFetch={modelConversionsFetch}
        />
      </Modal>
    </>
  );
};

const onTabChange = (key: string) => {};

const formatFormula = (inputFormula: string) => {
  if (!inputFormula) return;

  const formulaWithBlue = inputFormula
    .toUpperCase()
    .replace(
      /\[([A-Z]+)\]/g,
      '<span style="color: blue; font-weight: bold">$1</span>',
    );

  const formulaWithOrange = formulaWithBlue.replace(
    /(\b\w+\b)(?=\()/g,
    '<span style="color: red; font-weight: bold">$1</span>',
  );

  const formattedFormula = formulaWithOrange.replace(
    /{([^}]+)}/g,
    '<span style="color: orange; font-weight: bold">$1</span>',
  );

  return (
    <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
      <span
        style={{ fontFamily: "Consolas, monospace" }}
        dangerouslySetInnerHTML={{ __html: formattedFormula }}
      />
    </div>
  );
};
