import { InboxOutlined } from "@ant-design/icons";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  Input,
  Modal,
  Popconfirm,
  Upload,
  UploadProps,
  notification,
} from "antd";
import { RcFile } from "antd/lib/upload";
import React, { RefObject, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import ModelService from "../../../entities/model/ModelService";
import { IModel } from "../../../entities/types/IModel";
import { IBackResponse } from "../../../entities/types/response";
import ModelModal from "../../../features/componets/ModelModal";
import Button from "../../../shared/components/button";
import { Loader, LoaderFullScreen } from "../../../shared/components/loader";
import { download } from "../../../shared/helper/download";

const { Dragger } = Upload;

type ImportProps = {
  onImport?: (file: RcFile) => Promise<void>;
  onClose?: () => Promise<void> | void;
};

const Import: React.FC<ImportProps> = ({ onImport, onClose }) => {
  const [isLoading, setIsLoading] = useState(false);

  const props: UploadProps = {
    name: "file",
    multiple: false,
    beforeUpload: (file) => {
      setIsLoading(true);
      onImport?.(file)
        .then(async () => {
          notification.success({ message: `Модель успешно импортирована` });
          setIsLoading(false);
          await onClose?.();
        })
        .catch((error) => {
          notification.error({
            message: `Возникла ошибка при импорте модели: ${error}`,
          });
        });
    },
  };

  return (
    <Dragger {...props}>
      {isLoading && <LoaderFullScreen />}
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">
        Выберите или перетащите JSON файл модели для импорта
      </p>
    </Dragger>
  );
};

const ModelPage: React.FC = () => {
  const [modelTable, setModelTable] = useState<IModel[]>([]);
  const modalRef: RefObject<any> = useRef();
  const [loadingState, setLoadingState] = useState<boolean>(false);
  const [filterText, setFilterText] = useState("");
  const [importModalVisible, setImportModalVisible] = useState(false);

  const fetchData = async () => {
    setLoadingState(true);
    try {
      const list: IBackResponse = await ModelService.getAll();
      if (list) setModelTable(list.data);
    } catch (error) {
      console.error(error);
    }
    setLoadingState(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onModelDelete = async (model: string) => {
    const response = await ModelService.delete(model);
    notification.warning({
      message: response.text,
      description: `Код ответа: ${response.code}`,
    });
    fetchData();
  };

  const onModelExport = async (model: string) => {
    const response = await ModelService.exportModel(model);

    notification.info({
      message: response.text,
      description: `Код ответа: ${response.code}`,
    });

    download(
      `${model}.json`,
      new Blob([JSON.stringify(response.data)], { type: "text/json" }),
    );
  };

  const onModelExportNA = async (model: string) => {
    const response = await ModelService.exportModelNA(model);

    notification.info({
      message: response.text,
      description: `Код ответа: ${response.code}`,
    });

    download(
      `${model}.json`,
      new Blob([JSON.stringify(response.data)], { type: "text/json" }),
    );
  };

  const onModelImport = async (file: RcFile) => {
    const text = await file.text();
    const data = JSON.parse(text);

    const response = await ModelService.importModel(data);

    if (response.code !== 1) {
      throw new Error(`Не удалось импортировать модель: ${response.text}`);
    }

    await fetchData();
  };

  return (
    <div className="pageAttribute">
      <div
        className="ManageTable"
        style={{ display: "flex", flexDirection: "row", gap: "15px" }}
      >
        <Button
          type="primary"
          size="large"
          label="📁 Создать"
          onClick={modalRef.current?.openCreateModal}
        />
        <Button
          label="🔄 Обновить"
          size="large"
          type="default"
          onClick={fetchData}
        />
        <Button
          label="📥 Импорт"
          size="large"
          type="default"
          onClick={() => setImportModalVisible(true)}
        />
      </div>
      <Input
        placeholder="Поиск"
        style={{ marginTop: "15px", marginBottom: "15px" }}
        value={filterText}
        onChange={(e) => setFilterText(e.target.value)}
      />
      <TableContainer>
        {loadingState && <Loader />}
        {!loadingState && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: "bold" }}>ID</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Наименование
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Описание</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Действие</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {modelTable &&
                modelTable
                  .filter((model) => {
                    const searchText = filterText.toLowerCase();
                    return (
                      model.id.toLowerCase().includes(searchText) ||
                      model.name.toLowerCase().includes(searchText)
                    );
                  })
                  .map((el: IModel, index: number) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>
                          <Link
                            to={`/model/view/${el.id}`}
                            style={{ fontWeight: "bold" }}
                          >
                            {el.id}
                          </Link>
                        </TableCell>
                        <TableCell>{el.name}</TableCell>
                        <TableCell>{el.description}</TableCell>
                        <TableCell>
                          <div style={{ display: "flex", gap: "10px" }}>
                            <Button
                              type="primary"
                              label="⬇ Экспорт"
                              onClick={() => onModelExport(el.id)}
                            />
                            <Button
                              type="primary"
                              label="⬇ Экспорт (НА)"
                              onClick={() => onModelExportNA(el.id)}
                            />
                            <Popconfirm
                              title="Удаление модели"
                              description={
                                <>
                                  <span>
                                    Вы действительно хотите удалить модель{" "}
                                    {el.id}?
                                  </span>
                                  <br />
                                  <span
                                    style={{ fontWeight: "bold", color: "red" }}
                                  >
                                    ЭТО БЕЗВОЗВРАТНОЕ ДЕЙСТВИЕ, ВСЕ ДАННЫЕ БУДУТ
                                    УДАЛЕНЫ!
                                  </span>
                                </>
                              }
                              onConfirm={() => onModelDelete(el.id)}
                              okText="⚠ Да, удалить"
                              okType={"danger"}
                              cancelText="Нет, отменить"
                            >
                              <Button type="primary" label="🗑 Удалить" />
                            </Popconfirm>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      <ModelModal
        apiRef={modalRef}
        attributeTable={modelTable}
        fetchData={fetchData}
      />
      <Modal
        title="Импорт модели"
        open={importModalVisible}
        footer={[]}
        onCancel={() => setImportModalVisible(false)}
      >
        <Import
          onImport={onModelImport}
          onClose={() => setImportModalVisible(false)}
        />
      </Modal>
    </div>
  );
};

export default ModelPage;
