import { Edge, Node } from "@xyflow/react";
import { IFlowVariableField } from "../../pages/integration/integrationConstructor/modalVariables";
import $api, { API_URL } from "../../shared/api/axios";
import { BackResponse, IBackResponse } from "../types/response";

export default class FlowService {
  static async getProjects(): Promise<IBackResponse> {
    const response = await $api.get(`${API_URL.xTransfer}/flow/projects`);
    return new BackResponse(response.data);
  }

  static async getAll(
    params: {
      page?: number;
      size?: number;
      filters?: any[];
      sorting?: any[];
    } = {},
  ): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xTransfer}/flow/list`, params);
    return new BackResponse(response.data);
  }

  static async getOne(id: string): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xTransfer}/flow/getone`, {
      id,
    });

    response.data.data = response.data.data ?? {};

    // FIXME: remove after backend fix
    response.data.data.nodes = (response.data.data.nodes || []).map(
      (node: any) => ({
        id: node.id,
        type: node.type,
        height: node.height,
        width: node.width,
        position: node.position,
        positionAbsolute: node.positionAbsolute,
        data: node.data,
      }),
    );

    // FIXME: remove after backend fix
    response.data.data.edges = (response.data.data.edges || []).map(
      (edge: any) => ({
        id: edge.id,
        source: edge.source,
        sourceHandle: edge.sourceHandle,
        target: edge.target,
        targetHandle: edge.targetHandle,
        type: edge.type,
      }),
    );

    return new BackResponse(response.data);
  }

  static async create(
    id: string,
    project: string,
    nodes: Node[],
    edges: Edge[],
    variables: IFlowVariableField[],
  ): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xTransfer}/flow/create`, {
      id,
      project,
      nodes,
      edges,
      variables,
    });
    return new BackResponse(response.data);
  }

  static async update(
    id: string,
    project: string,
    nodes: Node[],
    edges: Edge[],
    variables: IFlowVariableField[],
  ): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xTransfer}/flow/update`, {
      id,
      project,
      nodes,
      edges,
      variables,
    });
    return new BackResponse(response.data);
  }

  static async copy(old_id: string, new_id: string): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xTransfer}/flow/copy`, {
      old_id,
      new_id,
    });
    return new BackResponse(response.data);
  }

  static async rename(old_id: string, new_id: string): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xTransfer}/flow/rename`, {
      old_id,
      new_id,
    });
    return new BackResponse(response.data);
  }

  static async delete(id: string): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xTransfer}/flow/delete`, {
      id,
    });
    return new BackResponse(response.data);
  }

  static async getLogsAll(
    filter: { field: string; operator: string; value: string | string[] }[],
  ): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xTransfer}/flow/getlogs`, {
      filter,
    });
    return new BackResponse(response.data);
  }

  static async getLogs(params: {
    page: number;
    size: number;
    filters: any[];
    sorting: any[];
  }): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xTransfer}/flow/get`, params);
    return new BackResponse(response.data);
  }

  static async getUsers(id: string): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xTransfer}/flow/getusers`, {
      flow: id,
    });
    return new BackResponse(response.data);
  }

  static async share(
    flow: string,
    user: number,
    access: string,
  ): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xTransfer}/flow/share`, {
      flow,
      user,
      access,
    });
    return new BackResponse(response.data);
  }

  static async unshare(
    flow: string,
    user: number,
    access: string,
  ): Promise<IBackResponse> {
    const response = await $api.post(`${API_URL.xTransfer}/flow/unshare`, {
      flow,
      user,
      access,
    });
    return new BackResponse(response.data);
  }
}
