import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { Input, Popover, Tag } from "antd";
import { Modal, notification } from "antd/lib";
import { useMemo, useState } from "react";
import FlowService from "../../../entities/model/FlowService";
import UserService from "../../../entities/model/UserService";
import Loader, { LoaderFullScreen } from "../../../shared/components/loader";

type Props = {
  flowId: string;
  modalState: boolean;
  setModalState: (state: boolean) => void;
};

export const RolesModal: React.FC<Props> = (props) => {
  const { flowId, modalState, setModalState } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filterText, setFilterText] = useState("");

  const sharedQuery = useQuery({
    queryKey: ["flow-users", flowId],
    queryFn: async () => {
      const response = await FlowService.getUsers(flowId);

      if (response.code !== 1) {
        throw new Error(response.text);
      }

      return response.data;
    },
  });

  const usersQuery = useQuery({
    queryKey: ["users"],
    queryFn: async () => {
      const response = await UserService.getUserList();

      if (response.code !== 1) {
        throw new Error(response.text);
      }

      return response.data;
    },
  });

  const sharedUsers = useMemo(() => {
    if (Array.isArray(sharedQuery.data)) {
      const groups = { READ: new Set(), WRITE: new Set(), RUN: new Set() };

      sharedQuery.data.forEach((user) => {
        user.roles.forEach((access: string) => {
          groups[access].add(user.id);
        });
      });

      return groups;
    }
  }, [sharedQuery.data]);

  if (sharedQuery.isLoading || usersQuery.isLoading) {
    return (
      <Modal
        title="Управление доступами"
        width={"100%"}
        open={modalState}
        onCancel={() => setModalState(false)}
        footer={[]}
      >
        <Loader />
      </Modal>
    );
  }

  if (typeof sharedUsers === "undefined") {
    return null;
  }

  const users = usersQuery.data
    .filter((user) => {
      const searchText = filterText.toLowerCase();
      return (
        user.id.toString().toLowerCase().includes(searchText) ||
        user.name.toLowerCase().includes(searchText) ||
        user.surname.toLowerCase().includes(searchText) ||
        user.email.toLowerCase().includes(searchText)
      );
    })
    .map((user: any) => ({
      ...user,
      hasREADAccess: sharedUsers["READ"].has(user.id),
      hasWRITEAccess: sharedUsers["WRITE"].has(user.id),
      hasRUNAccess: sharedUsers["RUN"].has(user.id),
    }));

  const refetch = async () => {
    await Promise.all([usersQuery.refetch(), sharedQuery.refetch()]);
  };

  const runWithLoaderAndRefetch = async (fn: () => Promise<any>) => {
    setIsLoading(true);
    await fn();
    await refetch();
    setIsLoading(false);
  };

  const onUnshare = async (userId: number, access: string) => {
    const response = await FlowService.unshare(flowId, userId, access);

    const notificationFn =
      response.code !== 1 ? notification.error : notification.success;

    notificationFn({
      message: response.text,
    });
  };

  const onShare = async (userId: number, access: string) => {
    const response = await FlowService.share(flowId, userId, access);

    const notificationFn =
      response.code !== 1 ? notification.error : notification.success;

    notificationFn({
      message: response.text,
    });
  };

  return (
    <Modal
      title="Управление доступами"
      width={"100%"}
      open={modalState}
      onCancel={() => setModalState(false)}
      footer={[]}
    >
      {isLoading && <LoaderFullScreen />}
      <TableContainer>
        <Input
          placeholder="Поиск"
          style={{ marginTop: "15px", marginBottom: "15px" }}
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
        />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>Аватар</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>ИД</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Имя</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Фамилия</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Email</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Активирован</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Заблокирован</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Роли</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Фильтры</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Действия</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow>
                <TableCell>
                  {user.avatar ? (
                    <img src={user.avatar} width={70} alt={user.surname} />
                  ) : (
                    "-"
                  )}
                </TableCell>
                <TableCell>{user.id}</TableCell>
                <TableCell>{user.name}</TableCell>
                <TableCell>{user.surname}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>
                  {user.activated ? (
                    <Tag color="green">ДА</Tag>
                  ) : (
                    <Tag color="red">НЕТ</Tag>
                  )}
                </TableCell>
                <TableCell>
                  {user.blocked ? (
                    <Tag color="green">ДА</Tag>
                  ) : (
                    <Tag color="red">НЕТ</Tag>
                  )}
                </TableCell>
                <TableCell>
                  {user.roles.map((role: string) => (
                    <Tag color="orange-inverse">{role}</Tag>
                  ))}
                </TableCell>
                <TableCell>
                  {user.filters.map((filter: string) => (
                    <Tag>{filter}</Tag>
                  ))}
                </TableCell>
                <TableCell>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "15px",
                    }}
                  >
                    {["READ", "WRITE", "RUN"].map((access) =>
                      user[`has${access}Access`] ? (
                        <Popover content={"Закрыть доступ"}>
                          <Tag
                            color="green"
                            onClick={() =>
                              runWithLoaderAndRefetch(() =>
                                onUnshare(user.id, access),
                              )
                            }
                            style={{ cursor: "pointer" }}
                          >
                            {access}
                          </Tag>
                        </Popover>
                      ) : (
                        <Popover content={"Открыть доступ"}>
                          <Tag
                            color="red"
                            onClick={() =>
                              runWithLoaderAndRefetch(() =>
                                onShare(user.id, access),
                              )
                            }
                            style={{ cursor: "pointer" }}
                          >
                            {access}
                          </Tag>
                        </Popover>
                      ),
                    )}
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Modal>
  );
};

export default RolesModal;
