import axios from "axios";
import AuthService from "../../entities/model/AuthService";
import { env } from "../../env";

console.log(env);

export const API_URL: any = {
  xKS: `${env.REACT_APP_API_URL}/ks`,
  xMath: `${env.REACT_APP_API_URL}/math`,
  xModel: `${env.REACT_APP_API_URL}/model`,
  xObject: `${env.REACT_APP_API_URL}/object`,
  xSyncAgent: `${env.REACT_APP_API_URL}/sync`,
  xTransfer: `${env.REACT_APP_API_URL}/transfer`,
  xUser: `${env.REACT_APP_API_URL}/user`,
  // xOptimizer: `${env.REACT_APP_API_URL}:25025`,
  // xLog: `${env.REACT_APP_API_URL}:25003`,
  // xSC: `${env.REACT_APP_API_URL}:25004`,
};

const $api = axios.create({
  withCredentials: true,
});

$api.interceptors.request.use((config: any) => {
  const accessToken = localStorage.getItem("accessToken");

  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }

  const refreshToken = localStorage.getItem("refreshToken");

  if (refreshToken) {
    config.headers["X-RefreshToken"] = refreshToken;
  }

  return config;
});

$api.interceptors.response.use(
  (config: any) => {
    return config;
  },
  async (error: any) => {
    const originalRequest = error.config;
    if (
      error.response.status === 401 &&
      error.config &&
      !error.config._isRetry
    ) {
      originalRequest._isRetry = true;
      try {
        const response = await AuthService.refresh();
        localStorage.setItem("accessToken", response.data.data.accessToken);
        localStorage.setItem("refreshToken", response.data.data.refreshToken);

        return $api.request(originalRequest);
      } catch (e) {
        console.log("Not authorized");
      }
    }

    throw error;
  },
);

export default $api;

// @ts-ignore
window.API_URL = API_URL;
// @ts-ignore
window.$api = $api;
